import { Calendar } from "@fullcalendar/core";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import listPlugin from "@fullcalendar/list";
import bootstrapPlugin from "@fullcalendar/bootstrap";
$(document).on("turbolinks:load", function() {
  if (typeof events_list !== "undefined" && $("#calendar").length) {
    var calendarEl = document.getElementById("calendar");
    var calendar = new Calendar(calendarEl, {
      timeZone: "UTC",
      height: 430,
      plugins: [dayGridPlugin, timeGridPlugin, listPlugin, bootstrapPlugin],
      themeSystem: "bootstrap",
      eventRender: function(info) {
        var el = $(info.el);
        var eventObj = info.event.extendedProps;
        el.popover({
          title: info.event.title,
          html: true,
          content: "<b>Todo</b><br>" + eventObj.note,
          trigger: "hover",
          placement: "top",
          container: "body"
        });
      },
      header: {
        left: "prev,next today",
        center: "title",
        // right: "month,agendaWeek,agendaDay,listDay,listWeek",
        right: "dayGridMonth,timeGridWeek,listWeek,timeGridDay,listDay"
      },
      views: {
        listDay: { buttonText: "list day" },
        listWeek: { buttonText: "list week" }
      },
      navLinks: true,

      selectable: true,
      eventLimit: true,
      next: "fal fa-chevron-right",
      events: events_list
    });

    calendar.render();
  }
});
