import { Controller } from 'stimulus';
import { chain, evaluate } from 'mathjs';
export default class extends Controller {
  static targets = [
    'purchase_price',
    'initial_investment',
    'price_per_unit',
    'known_gpri',
    'adjusted_grpi',
    'initial_loan',
    'total_amount',
    'payments_year',
    'term_years',
    'periodic_payment',
    'goi',
    'op_expense',
    'cash_flow_before_taxes',
    'annual_debt_service',
    'annual_debt_service_primary',

    'square_footage',
    'number_of_units',

    'w1_cap_rate',
    'w2_value',
    'w3_sale_cash_out_pre_tax',
    'w4_re_fi_prect',
    'w5_re_fi_proceeds',
    'w6_re_fi_cash_out',
    're_fi_int_rate',
    're_fi_am_pd',
    'term_years',
    'dcr_b',
    'dcr_b_42',
    'dcr_i_43',
    'cap_rate_44',
    'c_on_c_45',
    'gross_rent_multiplier',
    'mortgages_amount',
    'change_input',
  ];
  connect() {
    var elementExists2 = document.getElementById('change_input2');
    var formV2Exists = document.getElementById('form_v2');
    if (elementExists2 && formV2Exists) {
      this.calculate_summary_what_ifs();
    }
  }

  calculate_summary_what_ifs() {
    var noi = chain(this.goi).subtract(this.op_expense).round(2).done();
    this.w2_valueTarget.value = chain(noi)
      .divide(this.w1_cap_rate / 100)
      .round(2)
      .done();
    this.w3_sale_cash_out_pre_taxTarget.value =
      this.w2_value - this.mortgages_amount;
    this.w5_re_fi_proceedsTarget.value = chain(this.w2_value)
      .multiply(this.w4_re_fi_prect / 100)
      .round(2)
      .done();
    this.w6_re_fi_cash_outTarget.value = chain(this.w5_re_fi_proceeds)
      .subtract(this.mortgages_amount)
      .round(2)
      .done();

    console.log(noi, this.annual_debt_service_primary);

    console.log(this.dcr_b_42Target.value)
    this.dcr_b_42Target.value = chain(noi)
      .divide(this.annual_debt_service_primary)
      .round(2)
      .done();
    
    // this.dcr_b_42Target.value = 0

    this.dcr_i_43Target.value = chain(noi)
      .divide(this.annual_debt_service)
      .round(2)
      .done();

    this.cap_rate_44Target.value = chain(noi)
      .divide(this.purchase_price)
      .multiply(100)
      .round(2)
      .done();

    this.c_on_c_45Target.value = chain(this.cash_flow_before_taxes)
      .divide(this.initial_investment)
      .multiply(100)
      .round(2)
      .done();

    // this.c_on_c_45Target.value = 0

    if (this.w5_re_fi_proceeds != '') {
      $.get(
        '/calc_periodic_payment',
        {
          apr: this.re_fi_int_rate / 100.0,
          initial_loan: this.w5_re_fi_proceeds,
          duration: this.term_years * 12,
          interest_only: false,
        },
        (data) => {
          var dcrb = chain(noi)
            .divide(data.periodic_payment * 12)
            .round(8)
            .done();
          if (!isFinite(dcrb)) {
            dcrb = 0;
          }
          this.dcr_bTarget.value = dcrb;
        }
      );
    }

    // this.dcr_bTarget.value = chain(noi)
    //   .divide(this.annual_debt_service)
    //   .round(2)
    //   .done();

    var gpri = this.goi;

    if (this.known_gpri != 0) {
      gpri = this.known_gpri;
    }

    this.gross_rent_multiplierTarget.value = chain(gpri)
      .divide(this.purchase_price)
      .multiply(100)
      .round(2)
      .done();
  }

  get purchase_price() {
    return this.purchase_priceTarget.value;
  }
  get initial_investment() {
    return this.initial_investmentTarget.value;
  }
  get price_per_unit() {
    return this.price_per_unitTarget.value;
  }

  get known_gpri() {
    return this.known_gpriTarget.value;
  }

  get adjusted_grpi() {
    return this.adjusted_grpiTarget.value;
  }

  get total_amount() {
    return this.total_amountTarget.value;
  }
  get term_years() {
    return this.term_yearsTarget.value;
  }
  get interest_only() {
    return $(this.interest_onlyTarget).is(':checked');
  }
  get goi() {
    return this.goiTarget.value;
  }
  get op_expense() {
    return this.op_expenseTarget.value;
  }
  get annual_debt_service() {
    return this.annual_debt_serviceTarget.value;
  }
  get annual_debt_service_primary() {
    return this.annual_debt_service_primaryTarget.value;
  }
  get square_footage() {
    return this.square_footageTarget.value;
  }
  get number_of_units() {
    return this.number_of_unitsTarget.value;
  }
  get w1_cap_rate() {
    return this.w1_cap_rateTarget.value;
  }

  get w2_value() {
    return this.w2_valueTarget.value;
  }
  get w3_sale_cash_out_pre_tax() {
    return this.w3_sale_cash_out_pre_taxTarget.value;
  }
  get w4_re_fi_prect() {
    return this.w4_re_fi_prectTarget.value;
  }
  get w5_re_fi_proceeds() {
    return this.w5_re_fi_proceedsTarget.value;
  }
  get w6_re_fi_cash_out() {
    return this.w6_re_fi_cash_outTarget.value;
  }
  get re_fi_int_rate() {
    return this.re_fi_int_rateTarget.value;
  }
  get re_fi_am_pd() {
    return this.re_fi_am_pdTarget.value;
  }
  get term_years() {
    return this.term_yearsTarget.value;
  }
  get dcr_b() {
    return this.dcr_bTarget.value;
  }
  get dcr_b_42() {
    return this.dcr_b_42Target.value;
  }
  get dcr_i_43() {
    return this.dcr_i_43Target.value;
  }
  get cap_rate_44() {
    return this.cap_rate_44Target.value;
  }
  get c_on_c_45() {
    return this.c_on_c_45Target.value;
  }
  get gross_rent_multiplier() {
    return this.gross_rent_multiplierTarget.value;
  }
  get mortgages_amount() {
    return this.mortgages_amountTarget.value;
  }
  get cash_flow_before_taxes() {
    return this.cash_flow_before_taxesTarget.value;
  }
}