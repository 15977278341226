import List from "list.js";
$(document).on("turbolinks:load", function () {
  // var options = {
  //   valueNames: ["name"],
  //   page: 3,
  //   pagination: true,
  // };
  // if ($("#articles-list").length) {
  //   var articlesList = new List("articles-list", options);
  //   //requiredCurriculum.sort("status", { order: "desc" });
  //   //curriculumList.sort("status", { order: "desc" });
  // }

  var options = {
    valueNames: ["name", "updated", "desc"],
  };
  var articlesList = new List("articles", options);

  //updateList();
  if ($("#articles").length) {
    articlesList.on("updated", function (list) {
      if (list.matchingItems.length > 0) {
        $(".no-result").hide();
      } else {
        $(".no-result").show();
      }
    });
  }
  $(".view_article").on("click", function (e) {
    e.stopPropagation();
    e.preventDefault();
    var id = $(this).data("id");
    $.get("article_data/" + id).done(function (data) {
      $(".article-title").html(data.title);
      $(".article-body").html(data.body);
      $(".not-selected").addClass("d-none");
    });
    $(".articles-list li a").not(this).removeClass("active");
    $(this).addClass("active");
  });
});
