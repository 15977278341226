// Visit The Stimulus Handbook for more details
// https://stimulusjs.org/handbook/introduction
//
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "stimulus";
import { chain, evaluate } from "mathjs";
export default class extends Controller {
  static targets = [
    "purchase_price",
    "sq_ft",
    "gross_potential_rental_income",
    "vacancy_credit_loss",
    "additional_income",
    "cam_recapture",
    // "gross_operating_income_v",
    "gross_operating_income",
    "operating_expenses",
    "operating_expenses_percentage",
    "net_operating_income_v",
    "net_operating_income",
    "cap_rate_desired",
    "cap_rate_actual",
    "cap_rate_actual_v",
    "price_per_sqft_v",
    "price_per_sqft",
    "value_per_sqft_v",
    "value_per_sqft",
    "value_v",
    "value",
  ];
  calculate() {
    console.log("calculate");
    var final = chain(this.gross_potential_rental_income)
      .multiply(this.vacancy_credit_loss)
      .add(this.additional_income)
      .add(this.cam_recapture)
      .round(2)
      .done();
    this.gross_operating_incomeTarget.value = final;
    // this.gross_operating_income_vTarget.value = final;

    var operating_expenses_percentage = chain(this.operating_expenses)
      .divide(final)
      .multiply(100)
      .round(4)
      .done();
    this.operating_expenses_percentageTarget.value =
      operating_expenses_percentage;

    var noi = chain(final).subtract(this.operating_expenses).done();
    this.net_operating_incomeTarget.value = noi;
    this.net_operating_income_vTarget.value = noi;

    var cap_rate_actual = chain(noi)
      .divide(this.purchase_price)
      .multiply(100)
      .round(4)
      .done();

    this.cap_rate_actualTarget.value = cap_rate_actual;
    this.cap_rate_actual_vTarget.value = cap_rate_actual;
    if (cap_rate_actual < this.cap_rate_desired) {
      console.log(this.cap_rate_actualTarget);
      $(this.cap_rate_actualTarget)
        .removeClass("text-success")
        .addClass("text-danger");
    } else {
      $(this.cap_rate_actualTarget)
        .removeClass("text-danger")
        .addClass("text-success");
    }

    if (this.sq_ft) {
      var price_per_sqft = chain(this.purchase_price)
        .divide(this.sq_ft)
        .round(2)
        .done();

      this.price_per_sqftTarget.value = price_per_sqft;
      this.price_per_sqft_vTarget.value = price_per_sqft;
    }
    if (this.cap_rate_desired) {
      var total_value = chain(noi)
        .divide(this.cap_rate_desired)
        .multiply(100)
        .round(2)
        .done();

      var value_per_sqft = chain(total_value)
        .divide(this.sq_ft)
        .round(2)
        .done();

      this.value_per_sqftTarget.value = value_per_sqft;
      this.value_per_sqft_vTarget.value = value_per_sqft;
      if (value_per_sqft < this.price_per_sqft) {
        $(this.value_per_sqftTarget)
          .removeClass("text-success")
          .addClass("text-danger");
      } else {
        $(this.value_per_sqftTarget)
          .removeClass("text-danger")
          .addClass("text-success");
      }
      this.valueTarget.value = total_value;
      this.value_vTarget.value = total_value;
      if (total_value < this.purchase_price) {
        $(this.valueTarget).removeClass("text-success").addClass("text-danger");
      } else {
        $(this.valueTarget).removeClass("text-danger").addClass("text-success");
      }
    }
  }

  get purchase_price() {
    return this.purchase_priceTarget.value;
  }
  get sq_ft() {
    return this.sq_ftTarget.value;
  }
  get gross_potential_rental_income() {
    return this.gross_potential_rental_incomeTarget.value;
  }
  get vacancy_credit_loss() {
    if (this.vacancy_credit_lossTarget.value) {
      return evaluate(1 - this.vacancy_credit_lossTarget.value / 100);
    } else {
      return 0;
    }
  }
  get additional_income() {
    return this.additional_incomeTarget.value;
  }
  get cam_recapture() {
    return this.cam_recaptureTarget.value;
  }

  get operating_expenses_percentage() {
    return this.operating_expenses_percentageTarget.value;
  }
  get operating_expenses() {
    return this.operating_expensesTarget.value;
  }
  get cap_rate_actual() {
    return this.cap_rate_actualTarget.value;
  }
  get cap_rate_desired() {
    return this.cap_rate_desiredTarget.value;
  }
  get price_per_sqft() {
    return this.price_per_sqftTarget.value;
  }
  get value_per_sqft() {
    return this.value_per_sqftTarget.value;
  }
  get value() {
    return this.valueTarget.value;
  }
}
