import Inputmask from "inputmask";
Inputmask.extendDefaults({
  autoUnmask: true,
});
// var currencyMask = IMask(document.getElementById("currency"), {
$(document).on("turbolinks:load", function () {
  Inputmask({
    alias: "currency",
    prefix: "$",
    digitsOptional: false,
    autoUnmask: true,
    removeMaskOnSubmit: true,
    clearMaskOnLostFocus: false,
    digits: 0,
    positionCaretOnClick: "select",
  }).mask($(".currency"));
  Inputmask({
    alias: "currency",
    prefix: "$",
    digitsOptional: true,
    autoUnmask: true,
    removeMaskOnSubmit: true,
    clearMaskOnLostFocus: false,
    digits: 2,
    positionCaretOnClick: "select",
  }).mask($(".currency2"));

  Inputmask({
    alias: "currency",
    prefix: "$",
    digitsOptional: true,
    autoUnmask: true,
    removeMaskOnSubmit: true,
    clearMaskOnLostFocus: false,
    // positionCaretOnClick: 'select',
  }).mask($(".currency_with_decimals"));

  Inputmask({
    alias: "currency",
    prefix: "$",
    // turning digitsOptional prop false allows negative numbers to be converted correctly
    digitsOptional: false,
    autoUnmask: true,
    removeMaskOnSubmit: true,
    clearMaskOnLostFocus: false,
    // positionCaretOnClick: 'select',
  }).mask($(".currency_with_decimals_v2"));

  Inputmask({
    alias: "currency",
    prefix: "$",
    digits: 2,
    digitsOptional: false,
    autoUnmask: true,
    removeMaskOnSubmit: true,
    clearMaskOnLostFocus: false,
    // positionCaretOnClick: 'select',
  }).mask($(".currency_round2"));
  Inputmask({
    rightAlign: true,
    alias: "currency",
    prefix: "$",
    digitsOptional: true,
    autoUnmask: true,
    removeMaskOnSubmit: true,
    clearMaskOnLostFocus: false,
    positionCaretOnClick: "select",
    // positionCaretOnClick: 'none',
  }).mask($(".currency_with_decimals_right"));

  Inputmask({
    autoUnmask: true,
    alias: "decimal",
    groupSeparator: ",",
    digits: 2,
    digitsOptional: true,
    removeMaskOnSubmit: true,
    clearMaskOnLostFocus: false,
    positionCaretOnClick: "select",
    // positionCaretOnClick: 'none',
  }).mask($(".decimal"));
  Inputmask({
    autoUnmask: true,
    alias: "decimal",
    groupSeparator: ",",
    digits: 1,
    digitsOptional: true,
    removeMaskOnSubmit: true,
    clearMaskOnLostFocus: false,
    positionCaretOnClick: "select",
    // positionCaretOnClick: 'none',
  }).mask($(".decimal_1"));
  Inputmask({
    suffix: "%",
    autoUnmask: true,
    alias: "decimal",
    groupSeparator: ",",
    digits: 2,
    digitsOptional: true,
    removeMaskOnSubmit: true,
    clearMaskOnLostFocus: false,
    positionCaretOnClick: "select",
    // positionCaretOnClick: 'none',
  }).mask($(".decimal_percnt"));
  Inputmask({
    suffix: "%",
    autoUnmask: true,
    alias: "decimal",
    groupSeparator: ",",
    digits: 8,
    digitsOptional: true,
    removeMaskOnSubmit: true,
    clearMaskOnLostFocus: false,
    positionCaretOnClick: "select",
    // positionCaretOnClick: 'none',
  }).mask($(".decimal_percnt_no_digits"));
  Inputmask({
    autoUnmask: true,
    alias: "integer",
    groupSeparator: ",",
    removeMaskOnSubmit: true,
    clearMaskOnLostFocus: false,
    positionCaretOnClick: "select",
    // positionCaretOnClick: 'none',
  }).mask($(".number"));
});
