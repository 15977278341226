import { chain, evaluate } from "mathjs";
$(document).on("turbolinks:load", function () {
  $(document).on(
    "keyup",
    ".rent_per_month, .start_year, .end_year",
    function () {
      var index = $(this).data("index");
      var total_sqft = $("#square_footage_val").val();
      console.log(total_sqft, $(".nested-fields").parent().index(this));

      var rent_per_month = $(".rent_per_month")
        .filter(`[data-index='${index}']`)
        .val();
      var rent_sqft = chain(rent_per_month)
        .divide(total_sqft)
        .multiply(12)
        .round(2)
        .done();
      var rent_per_year = chain(total_sqft).multiply(rent_sqft).round(2).done();
      $(".rent_per_sqft").filter(`[data-index='${index}']`).val(rent_sqft);
      $(".rent_per_year").filter(`[data-index='${index}']`).val(rent_per_year);
      var start_year = $(".start_year").filter(`[data-index='${index}']`).val();
      var end_year = $(".end_year").filter(`[data-index='${index}']`).val();
      var total_period_rent = chain(end_year)
        .subtract(start_year)
        .add(1)
        .multiply(rent_per_year)
        .round(2)
        .done();
      $(".total_period_rent")
        .filter(`[data-index='${index}']`)
        .val(total_period_rent);

      $(".annual_rent").filter(`[data-index='${index}']`).val(rent_per_year);
    }
  );

  $(document).on("keyup", ".rent_per_sqft", function () {
    var index = $(this).data("index");
    console.log(index);
    var total_sqft = $("#square_footage_val").val();
    var rent_sqft = $(".rent_per_sqft").filter(`[data-index='${index}']`).val();
    var rent_per_month = chain(total_sqft)
      .multiply(rent_sqft)
      .divide(12)
      .round(2)
      .done();
    var rent_per_year = chain(total_sqft).multiply(rent_sqft).round(2).done();
    $(".rent_per_month").filter(`[data-index='${index}']`).val(rent_per_month);
    $(".rent_per_year").filter(`[data-index='${index}']`).val(rent_per_year);
    var start_year = $(".start_year").filter(`[data-index='${index}']`).val();
    var end_year = $(".end_year").filter(`[data-index='${index}']`).val();
    var total_period_rent = chain(end_year)
      .subtract(start_year)
      .add(1)
      .multiply(rent_per_year)
      .round(2)
      .done();
    $(".total_period_rent")
      .filter(`[data-index='${index}']`)
      .val(total_period_rent);

    $(".annual_rent").filter(`[data-index='${index}']`).val(rent_per_year);
  });

  $(document).on("keyup", ".rent_per_year", function () {
    var index = $(this).data("index");
    var total_sqft = $("#square_footage_val").val();
    var rent_per_year = $(".rent_per_year")
      .filter(`[data-index='${index}']`)
      .val();
    var rent_sqft = chain(rent_per_year).divide(total_sqft).round(2).done();
    var rent_per_month = chain(total_sqft)
      .multiply(rent_sqft)
      .divide(12)
      .round(2)
      .done();
    // var rent_per_year = chain(rent_per_month).multiply(12).round(2).done();
    $(".rent_per_month").filter(`[data-index='${index}']`).val(rent_per_month);
    $(".rent_per_sqft").filter(`[data-index='${index}']`).val(rent_sqft);
    var start_year = $(".start_year").filter(`[data-index='${index}']`).val();
    var end_year = $(".end_year").filter(`[data-index='${index}']`).val();
    var total_period_rent = chain(end_year)
      .subtract(start_year)
      .add(1)
      .multiply(rent_per_year)
      .round(2)
      .done();
    $(".total_period_rent")
      .filter(`[data-index='${index}']`)
      .val(total_period_rent);

    $(".annual_rent").filter(`[data-index='${index}']`).val(rent_per_year);
  });
  $(document).on(
    "keyup",
    ".pstart_year, .pend_year, .ppercent, .sales_overage_point, .estimated_sales_per_year",
    function () {
      var index = $(this).data("index");
      var start_year = $(".pstart_year")
        .filter(`[data-index='${index}']`)
        .val();
      var end_year = $(".pend_year").filter(`[data-index='${index}']`).val();
      var pct = $(".ppercent").filter(`[data-index='${index}']`).val();
      var sop = $(".sales_overage_point")
        .filter(`[data-index='${index}']`)
        .val();
      var espy = $(".estimated_sales_per_year")
        .filter(`[data-index='${index}']`)
        .val();
      var total_value_percent_rent = 0;
      var annual_percent_rend = 0;
      var tvpr = chain(espy).subtract(sop).multiply(pct).round(2).done();
      if (tvpr <= 0) {
        $(".total_value_percent_rent").filter(`[data-index='${index}']`).val(0);
      } else {
        var yvalue = chain(end_year)
          .subtract(start_year)
          .add(1)
          .round(2)
          .done();
        var tvvalue = chain(tvpr).multiply(yvalue).divide(100).round(2).done();
        $(".total_value_percent_rent")
          .filter(`[data-index='${index}']`)
          .val(tvvalue);
        var yvalue = chain(tvvalue).divide(yvalue).round(2).done();
        $(".annual_percent_rend").filter(`[data-index='${index}']`).val(yvalue);
      }
    }
  );

  $(document).on(
    "keyup",
    ".start_month, .end_month, .rent, .rent_type",
    function () {
      var index = $(this).data("index");
      var total_sqft = $("#square_footage_val").val();
      var start_month = $(".start_month")
        .filter(`[data-index='${index}']`)
        .val();
      var end_month = $(".end_month").filter(`[data-index='${index}']`).val();
      var rent = $(".rent").filter(`[data-index='${index}']`).val();
      var rent_type = $(".rent_type").filter(`[data-index='${index}']`).val();
      var yvalue = chain(end_month)
        .subtract(start_month)
        .add(1)
        .round(2)
        .done();
      var sqft_month = chain(end_month)
        .subtract(start_month)
        .add(1)
        .round(2)
        .done();
      if (rent_type == "Per Month") {
        // var rent_month = rent;
        var rent_month = chain(rent).multiply(sqft_month).done();
        var monthly_buyout_cost = chain(rent).round(2).done();
        var annual_buyout_cost = chain(rent).multiply(12).round(2).done();
      } else if (rent_type == "$ Per Sq. Ft. Per Year") {
        console.log("hello");

        var rent_month = chain(rent)
          .multiply(total_sqft)
          .divide(sqft_month)
          .round(2)
          .done();
        var monthly_buyout_cost = chain(rent)
          .multiply(total_sqft)
          .divide(12)
          .round(2)
          .done();
        var annual_buyout_cost = chain(rent)
          .multiply(total_sqft)
          .round(2)
          .done();
      } else if (rent_type == "Per Year") {
        var rent_month = chain(rent).round(2).done();
        var monthly_buyout_cost = chain(rent).divide(12).round(2).done();
        var annual_buyout_cost = chain(rent).round(2).done();
      }
      $(".total_buyout_cost").filter(`[data-index='${index}']`).val(rent_month);
      $(".monthly_buyout_cost")
        .filter(`[data-index='${index}']`)
        .val(monthly_buyout_cost);
      $(".annual_buyout_cost")
        .filter(`[data-index='${index}']`)
        .val(annual_buyout_cost);
    }
  );
  $(document).on("change", ".rent_type", function () {
    var index = $(this).data("index");
    var total_sqft = $("#square_footage_val").val();
    var start_month = $(".start_month").filter(`[data-index='${index}']`).val();
    var end_month = $(".end_month").filter(`[data-index='${index}']`).val();
    var rent = $(".rent").filter(`[data-index='${index}']`).val();
    var rent_type = $(".rent_type").filter(`[data-index='${index}']`).val();
    var yvalue = chain(end_month).subtract(start_month).add(1).round(2).done();
    if (rent_type == "Per Month") {
      var rent_month = rent;
    } else if (rent_type == "$ Per Sq. Ft. Per Year") {
      var rent_month = chain(rent)
        .multiply(total_sqft)
        .divide(12)
        .round(2)
        .done();
    } else if (rent_type == "Per Year") {
      var rent_month = chain(rent).divide(12).round(2).done();
    }
    var monthly_buyout_cost = chain(rent_month).divide(yvalue).round(2).done();
    var annual_buyout_cost = chain(monthly_buyout_cost)
      .multiply(12)
      .round(2)
      .done();

    $(".total_buyout_cost").filter(`[data-index='${index}']`).val(rent_month);
    $(".monthly_buyout_cost")
      .filter(`[data-index='${index}']`)
      .val(monthly_buyout_cost);
    $(".annual_buyout_cost")
      .filter(`[data-index='${index}']`)
      .val(annual_buyout_cost);
  });
});
