// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

window.jQuery = $;
window.$ = $;
require("@rails/ujs").start();
require("turbolinks").start();
require("@rails/activestorage").start();
require("channels");
require("bootstrap/dist/js/bootstrap");
require("jquery-validation/dist/jquery.validate");
require("flatpickr/dist/flatpickr");
require("sweetalert");
require("trix");
require("@rails/actiontext");
import "cocoon";
import "controllers";
import Chart from "chart.js";
// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

var moment = require("moment");
window.moment = moment;
require("moment-timezone");
require("packs/modals");
require("packs/modal-confirm");
require("packs/masks");
require("packs/maps");
require("packs/fileupload");
require("packs/customer");
require("packs/chart");
require("packs/articles");
require("packs/evaluation");
require("packs/lease");
require("packs/sort");
require("sweetalert");

$(document).on("turbolinks:load", function () {
  $('input[readonly="true"]').each(function () {
    $(this).attr("tabindex", "-1");
  });
  $('input[readonly="readonly"]').each(function () {
    $(this).attr("tabindex", "-1");
  });
  $(".folders").on("click", "a", function () {
    let pid = $("#upload_pid").val();
    $.get("/property_files", {
      pid: pid,
      folder_id: $(this).data("id"),
    }).done(function (response) {
      $(".upload_files").html("");
      $.each(response, function (index, value) {
        if (value.viewale == true) {
          var tag =
            '<div class="col-md-2 mt-2 text-center"><a href="' +
            value.url +
            '"><img class="img-fluid"  style="height:70px;"  data-folder_id="' +
            value.folder +
            '" src="' +
            value.url +
            '"><br/><div class="mt-2 small">' +
            value.name +
            '</div></a><div class="del"><a class="btn btn-danger delete_file" data-id="' +
            value.id +
            '" href="#"><i class="fal fa-trash" aria-hidden="true"></i></a></div></div>';
        } else {
          var tag =
            '<div class="col-md-2 mt-2 text-center"><a href="' +
            value.url +
            '"><img class="img-fluid"  style="height:70px;"  data-folder_id="' +
            value.folder +
            '" src="' +
            $("#file_icon").attr("src") +
            '"><br/><div class="mt-2 small">' +
            value.name +
            '</div></a><div class="del"><a class="btn btn-danger delete_file" data-id="' +
            value.id +
            '" href="#"><i class="fal fa-trash" aria-hidden="true"></i></a></div></div>';
        }
        $(tag).appendTo(".upload_files");
      });
    });
    console.log("i ma here");
    $(".list-group-item").removeClass("active");
    $(this).addClass("active").siblings().removeClass("active");
    return false;
  });

  /* Start Calculator  */

  // $(".open_calc").click(function () {
  //   $(".calculator_container").addClass("show");
  //   return false;
  // });
  // $(".close_calc").click(function () {
  //   $(".calculator_container").removeClass("show");
  //   return false;
  // });
  if ($("#calculator").length > 0) {
    $(".open_calc + .dropdown-menu").on("click.bs.dropdown", function (e) {
      e.stopPropagation();
      e.preventDefault();
    });
    $("#calculator button").click(function (e) {
      e.stopPropagation();
    });
    // Shortcut to get elements
    var el = function (element) {
      if (element.charAt(0) === "#") {
        // If passed an ID...
        return document.querySelector(element); // ... returns single element
      }

      return document.querySelectorAll(element); // Otherwise, returns a nodelist
    };

    // Variables
    var viewer = el("#viewer"), // Calculator screen where result is displayed
      equals = el("#equals"), // Equal button
      nums = el(".num"), // List of numbers
      ops = el(".ops"), // List of operators
      theNum = "", // Current number
      oldNum = "", // First number
      resultNum, // Result
      operator; // Batman

    // When: Number is clicked. Get the current number selected
    var setNum = function () {
      if (resultNum) {
        // If a result was displayed, reset number
        theNum = this.getAttribute("data-num");
        resultNum = "";
      } else {
        // Otherwise, add digit to previous number (this is a string!)
        theNum += this.getAttribute("data-num");
      }

      viewer.innerHTML = theNum; // Display current number
    };

    // When: Operator is clicked. Pass number to oldNum and save operator
    var moveNum = function () {
      oldNum = theNum;
      theNum = "";
      operator = this.getAttribute("data-ops");

      equals.setAttribute("data-result", ""); // Reset result in attr
    };

    // When: Equals is clicked. Calculate result
    var displayNum = function () {
      // Convert string input to numbers
      oldNum = parseFloat(oldNum);
      theNum = parseFloat(theNum);

      // Perform operation
      switch (operator) {
        case "plus":
          resultNum = oldNum + theNum;
          break;

        case "minus":
          resultNum = oldNum - theNum;
          break;

        case "times":
          resultNum = oldNum * theNum;
          break;

        case "divided by":
          resultNum = oldNum / theNum;
          break;

        // If equal is pressed without an operator, keep number and continue
        default:
          resultNum = theNum;
      }

      // If NaN or Infinity returned
      if (!isFinite(resultNum)) {
        if (isNaN(resultNum)) {
          // If result is not a number; set off by, eg, double-clicking operators
          resultNum = "";
        } else {
          // If result is infinity, set off by dividing by zero
          resultNum = "Infinity";
        }
      }

      // Display result, finally!
      viewer.innerHTML = resultNum;
      equals.setAttribute("data-result", resultNum);

      // Now reset oldNum & keep result
      oldNum = 0;
      theNum = resultNum;
    };

    // When: Clear button is pressed. Clear everything
    var clearAll = function () {
      oldNum = "";
      theNum = "";
      viewer.innerHTML = "0";
      equals.setAttribute("data-result", resultNum);
    };

    var percentage = function () {
      resultNum = theNum / 100;
      theNum = resultNum;
      viewer.innerHTML = resultNum;
      equals.setAttribute("data-result", resultNum);
    };
    var plus_minus = function () {
      resultNum = theNum * -1;
      theNum = resultNum;
      viewer.innerHTML = resultNum;
      equals.setAttribute("data-result", resultNum);
    };

    /* The click events */

    // Add click event to numbers
    for (var i = 0, l = nums.length; i < l; i++) {
      nums[i].onclick = setNum;
    }

    // Add click event to operators
    for (var i = 0, l = ops.length; i < l; i++) {
      ops[i].onclick = moveNum;
    }

    // Add click event to equal sign
    equals.onclick = displayNum;

    // Add click event to clear button
    el("#clear").onclick = clearAll;
    el("#percentage").onclick = percentage;
    el("#plus_minus").onclick = plus_minus;
  }
  /* END Calculator */

  $('[data-toggle="tooltip"]').tooltip({ boundary: "window" });
  // datepicker
  flatpickr(".datepicker", { dateFormat: "m/d/Y", allowInput: true });
  // date with time
  flatpickr(".datetimepicker", {
    enableTime: true,
    dateFormat: "m/d/Y H:i",
  });

  $("input[readonly='readonly']").attr("tabindex", "-1");

  // remove start
  $(".role").on("click", function () {
    $(this).toggleClass("selected");
  });
  // remove end
  $(".select_location").on("change", function () {
    window.location = "/select_location?lid=" + this.value;
  });
  // // keep left nav toggle state
  // var nav_toggleed = Cookies.get("nav-minimize");
  // nav_toggleed = "true";
  // $("body").toggleClass("nav-minimize", nav_toggleed);
  // // navbar expand on mobile
  // if ($(window).width() <= 576) {
  //   $("body").addClass("nav-minimize");
  // }
  // // if clicked on minimize main nav icon on  header
  // $(".top-navbar").on("click", ".aside-toggler", function () {
  //   toggleNavbar(nav_toggleed);
  //   console.log("hi");
  //   return false;
  // });

  // keep left nav toggle state
  // var nav_toggleed = Cookies.get("nav-minimize");
  var nav_toggleed = Cookies.get("nav-minimize") === "false";

  $("body").toggleClass("nav-minimize", nav_toggleed);

  // navbar expand on mobile
  if ($(window).width() <= 576) {
    $("body").addClass("nav-minimize");
  }

  // if clicked on minimize main nav icon on  header
  $(".top-navbar").on("click", ".aside-toggler", function (e) {
    e.preventDefault();
    nav_toggleed = !$("body").is(".nav-minimize");
    $("body").toggleClass("nav-minimize", nav_toggleed);
    Cookies.set("nav-minimize", !nav_toggleed);
  });

  $(".reminder_done").on("click", function () {
    var el = $(this);
    var cardBody = el.closest(".card-body");

    $.get(el.data("url"));
    if (el.closest("ul").children().length == 1) {
      cardBody.append(
        '<div class="no_data">You have no reminders at this time.</div>'
      );
    }
    el.closest(".todo-item").remove();
  });

  $(".auto_save input").on("change", function () {
    var form = $(this).closest("form");
    var url = form.attr("action");
    var method = form.attr("method");
    $.ajax({
      type: method,
      url: url,
      data: form.serialize(),
      success: function (data) {
        console.log("saved");
      },
      dataType: "JSON",
    });
  });
});

function toggleNavbar(nav_toggleed) {
  //var $this = $(this);
  nav_toggleed = !nav_toggleed;
  $("body").toggleClass("nav-minimize", nav_toggleed);
  Cookies.set("nav-minimize", nav_toggleed);
  return false;
}

const dataTables = [];
const dataTablesCustom = [];
// dt_global_custom
document.addEventListener("turbolinks:load", () => {
  if (dataTables.length === 0 && $(".dt_global").length !== 0) {
    $(".dt_global").each((_, element) => {
      var defaultSortColumn = $(element).data("default-sort-column")
        ? $(element).data("default-sort-column")
        : 0;
      var defaultLength = $(element).data("length")
        ? $(element).data("length")
        : 25;
      var defaultSortDir = $(element).data("default-sort-dir")
        ? $(element).data("default-sort-dir")
        : "asc";
      console.log(defaultSortDir, defaultSortColumn);

      dataTables.push(
        $(element).DataTable({
          order: [[defaultSortColumn, defaultSortDir]],
          pageLength: defaultLength,
          // columnDefs: [{ targets: defaultSortColumn, type: "date-eu" }],
        })
      );
    });
  }
});

document.addEventListener("turbolinks:before-cache", () => {
  while (dataTables.length !== 0) {
    dataTables.pop().destroy();
  }
});
