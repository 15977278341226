const Uppy = require("@uppy/core");
const Dashboard = require("@uppy/dashboard");
const GoogleDrive = require("@uppy/google-drive");
const Dropbox = require("@uppy/dropbox");
// const Instagram = require("@uppy/instagram");
// const Facebook = require("@uppy/facebook");
// const OneDrive = require("@uppy/onedrive");
const Webcam = require("@uppy/webcam");
// const Tus = require("@uppy/tus");
const ActiveStorageUpload = require("uppy-activestorage-upload");

// import "@uppy/core/dist/style.css";
// import "@uppy/dashboard/dist/style.css";
// import "@uppy/webcam/dist/style.css";
function delete_file(id, type, obj) {
  var file =
    "Are you sure that you want to delete this file?  It cannot be undone.";
  var folder =
    "Are you sure that you want to delete this folder?  It cannot be undone and all contents will be deleted as well.";
  var msg = "";
  if (type == "file") {
    msg = file;
  } else {
    msg = folder;
  }
  if (confirm(msg)) {
    // $('#tmm1').hide('slow');
    $.ajax({
      url: "/delete_file",
      data: { id: id, type: type },
    }).done(function () {
      $(obj).parent().parent().remove();
    });
  }
}

$(document).on("turbolinks:load", function () {
  $(".delete_file").click(function () {
    var id = $(this).data("id");
    console.log(id);
    $(this).closest(".img_box").parent().remove();
    delete_file(id, "file", this);
    return false;
  });
  $(".delete_folder").click(function () {
    var id = $(this).data("id");
    $(this).closest(".folder").remove();
    delete_file(id, "folder", this);
    window.location = "";
    return false;
  });
  if ($("#file-dashboard").length > 0) {
    let direct_upload_url = document
      .querySelector("meta[name='direct-upload-url']")
      .getAttribute("content");
    let pid = $("#upload_pid").val();

    const uppy = Uppy({
      debug: true,
      autoProceed: false,
      proudlyDisplayPoweredByUppy: false,
      onBeforeFileAdded: (file, files) => {
        if (!file.data.name) {
          file.data.name = file.name;
        }
        return file;
      },
    })
      .use(Dashboard, {
        inline: true,
        target: "#file-dashboard",
        replaceTargetContent: true,
        showProgressDetails: true,
        note: "Images and video only, 2–3 files, up to 1 MB",
        height: 470,
        allowedFileTypes: [
          "image/*",
          ".jpg",
          ".jpeg",
          ".png",
          ".gif",
          ".xlsx",
          ".xls",
          ".doc",
          ".docx",
        ],
        metaFields: [
          { id: "name", name: "Name", placeholder: "file name" },
          {
            id: "caption",
            name: "Caption",
            placeholder: "describe what the image is about",
          },
        ],
        browserBackButtonClose: true,
      })
      .use(ActiveStorageUpload, {
        directUploadUrl: direct_upload_url,
      })

      .use(Webcam, { target: Dashboard })
      .use(GoogleDrive, {
        target: Dashboard,
        companionUrl: "http://0.0.0.0:3020",
      })
      .use(Dropbox, {
        target: Dashboard,
        companionUrl: "http://0.0.0.0:3020",
      });
    uppy.on("complete", (result) => {
      // console.log("successful files:", result.successful);
      // console.log("failed files:", result.failed);
      var uploaded_files = [];
      result.successful.forEach((file) => {
        uploaded_files.push(file.response.signed_id);
      });
      console.log(uploaded_files);
      $.post("/upload", {
        blob: uploaded_files,
        pid: pid,
        folder_id: $(".folders a.active").data("id"),
      }).done(function (response) {
        $(".upload_files").html("");
        $.each(response, function (index, value) {
          if (value.viewale == true) {
            var tag =
              '<div class="col-md-2 mt-2 text-center"><a href="' +
              value.url +
              '"><img class="img-fluid" style="height:70px;" data-folder_id="' +
              value.folder +
              '" src="' +
              value.url +
              '"><br/><div class="mt-2 small">' +
              value.name +
              '</div></a><div class="del"><a class="btn btn-danger delete_file" data-id="' +
              value.id +
              '" href="#"><i class="fal fa-trash" aria-hidden="true"></i></a></div></div>';
          } else {
            var tag =
              '<div class="col-md-2 mt-2 text-center"><a href="' +
              value.url +
              '"><img class="img-fluid" style="height:70px;"   data-folder_id="' +
              value.folder +
              '" src="' +
              $("#file_icon").attr("src") +
              '"><br/><div class="mt-2 small">' +
              value.name +
              '</div></a><div class="del"><a class="btn btn-danger delete_file" data-id="' +
              value.id +
              '" href="#"><i class="fal fa-trash" aria-hidden="true"></i></a></div></div>';
          }
          $(tag).appendTo(".upload_files");
          $("#v-pills-home-tab").trigger("click");
        });
      });
    });
  }
});
