// Visit The Stimulus Handbook for more details
// https://stimulusjs.org/handbook/introduction
//
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from 'stimulus';
import { chain, evaluate } from 'mathjs';
export default class extends Controller {
  static targets = [
    'sq_feet',
    'total_sq_feet',
    'sq_ft_year',
    'rent_per_month',
    'sum_sq_feets',
    'annual_rent',
    'months_expire',
    'lease_start',
    'lease_end',
    'sq_ft_year_real',
    'rent_per_month_real',
    'annual_rent_real',
  ];
  calculate() {
    this.total_sq_feetTarget.value = chain(this.sq_feet)
      .divide(this.sum_sq_feets)
      .multiply(100)
      .round(2)
      .done();

    if (this.lease_end) {
      this.months_expireTarget.value = moment(this.lease_end).diff(
        moment(),
        'months',
        false
      );
    }
    // // annual_rent
    // this.annual_rentTarget.value = chain(this.sq_ft_year)
    //   .multiply(this.sq_feet)
    //   .round(2)
    //   .done();

    // this.sq_ft_yearTarget.value = chain(this.annual_rent)
    //   .divide(this.sq_feet)
    //   .round(2)
    //   .done();

    // // rent_per_month
    // this.rent_per_monthTarget.value = chain(this.sq_ft_year)
    //   .divide(12)
    //   .multiply(this.sq_feet)
    //   .round(2)
    //   .done();
  }

  calculate_rent_per_month() {
    // rent_per_month
    this.annual_rentTarget.value = chain(this.rent_per_month)
      .multiply(12)
      .round(2)
      .done();

    this.sq_ft_yearTarget.value = chain(this.annual_rent)
      .divide(this.sq_feet)
      .round(2)
      .done();
  }

  calculate_annual_rent() {
    // annual_rent;
    this.sq_ft_yearTarget.value = chain(this.annual_rent)
      .divide(this.sq_feet)
      .round(2)
      .done();

    this.rent_per_monthTarget.value = chain(this.annual_rent)
      .divide(12)
      .round(2)
      .done();
  }

  calculate_sq_ft_year() {
    // .sq_ft_yea

    this.annual_rentTarget.value = chain(this.sq_ft_year)
      .multiply(this.sq_feet)
      .round(2)
      .done();

    this.rent_per_monthTarget.value = chain(this.annual_rent)
      .divide(12)
      .round(2)
      .done();
  }

  calculate_sq_ft() {
    // rent per sqft  per year
    // this.sq_ft_yearTarget.value = chain(this.rent_per_month)
    //   .multiply(12)
    //   .divide(this.sq_feet)
    //   .round(2)
    //   .done();
    // // annual_rent
    // this.annual_rentTarget.value = chain(this.sq_ft_year)
    //   .multiply(this.sq_feet)
    //   .round(2)
    //   .done();
  }

  calculate_rent() {
    // rent per sqft per year
    // this.sq_ft_yearTarget.value = chain(this.annual_rent)
    //   .divide(this.sq_feet)
    //   .round(2)
    //   .done();
    // // rent_per_month
    // this.rent_per_monthTarget.value = chain(this.sq_ft_year)
    //   .divide(12)
    //   .multiply(this.sq_feet)
    //   .round(2)
    //   .done();
  }

  get sq_feet() {
    return this.sq_feetTarget.value;
  }

  get total_sq_feet() {
    return this.total_sq_feetTarget.value;
  }

  get sq_ft_year() {
    return this.sq_ft_yearTarget.value;
  }

  get rent_per_month() {
    return this.rent_per_monthTarget.value;
  }

  get sum_sq_feets() {
    return this.sum_sq_feetsTarget.value;
  }
  get annual_rent() {
    return this.annual_rentTarget.value;
  }
  get months_expire() {
    return this.months_expireTarget.value;
  }
  get lease_start() {
    return this.lease_startTarget.value;
  }
  get lease_end() {
    return this.lease_endTarget.value;
  }

  get sq_ft_year_real() {
    return this.sq_ft_year_realTarget.value;
  }

  get rent_per_month_real() {
    return this.rent_per_month_realTarget.value;
  }

  get annual_rent_real() {
    return this.annual_rent_realTarget.value;
  }
}
