// Visit The Stimulus Handbook for more details
// https://stimulusjs.org/handbook/introduction
//
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from 'stimulus';
import { chain, evaluate } from 'mathjs';
export default class extends Controller {
  static targets = [
    'purchase_price',
    'mortgages',
    'lot_points',
    'acquisition_costs',
    'initial_investment',
    'price_per_sqft',
    'price_per_unit',
    'total_units',
    'total_sqft',
    'known_gpri',
    'raise_lower_by',
    'by_unit_month',
    'adjusted_grpi',
    'adjusted_grpi_v',
    'rent_roll',
    'pid',
    'id',
    'total_spaces',
    'economic_vacancy',
    'total_sqft_per_year',
    'gpri',
    'total_cam',
    'total_net',
    'total_addl',
    'total_perct_rent',
    'total_security',
    'calculated_grpi',
    // "calculated_grpi_v",
    'initial_loan',
    'loan_to_value',
    'loan_purchase_price',
    'rentable_sqft',
    'total_amount',
    'per_square_feet',
    'per_unit_month',
    'per_month',
    'per_unit',
    'payments_year',
    'interest_rate',
    'amoritization_period_years',
    'term_years',
    'periodic_payment',
    'interest_only',
    'gop_percentage',
    'operational_expense_percentage',
    'goi',
    'vacancy_and_credit_loss_percentage',
    'year_built',
    'actual_age',
    'due_diligence_type',
    'estimated_expense',
    'gpri_perct',
    'op_expense',
    'cash_flow_before_taxes',
    'annual_debt_service',
    'annual_debt_service_primary',
    'capital_additions',
    'leasing_commisions',
    'funded_reserves',

    'funded_per_sqft',
    'funded_Unit_per_month',
    'funded_per_month',
    'commisions_per_sqft',
    'commisions_Unit_per_month',
    'commisions_per_month',
    'capital_additions_per_sqft',
    'capital_additions_Unit_per_month',
    'capital_additions_per_month',
    'cash_flow_per_sqft',
    'cash_flow_Unit_per_month',
    'cash_flow_per_month',

    'square_footage',
    'number_of_units',

    'w1_cap_rate',
    'w2_value',
    'w3_sale_cash_out_pre_tax',
    'w4_re_fi_prect',
    'w5_re_fi_proceeds',
    'w6_re_fi_cash_out',
    're_fi_int_rate',
    're_fi_am_pd',
    'term_years',
    'dcr_b',
    'dcr_b_42',
    'dcr_i_43',
    'cap_rate_44',
    'c_on_c_45',
    'gross_rent_multiplier',
    'mortgages_amount',
    'loan_to_value_real',
    'vacant_sqft_perct',
    'change_input',
  ];
  connect() {
    var elementExists = document.getElementById('change_input');
    var elementExists2 = document.getElementById('change_input2');
    var formV2Exists = document.getElementById('form_v2');
    if (elementExists && !formV2Exists) {
      this.calculate_summary();
    }
    if (elementExists2 && !formV2Exists) {
      this.calculate_summary_what_ifs();
    }
  }

  calculate() {
    // initial_investment
    // var mortgages_lot = chain(this.mortgages)
    //   .multiply(this.lot_points)
    //   .round(2)
    //   .done();
    // this.initial_investmentTarget.value = chain(this.purchase_price)
    //   .subtract(this.mortgages)
    //   .add(mortgages_lot)
    //   .add(this.acquisition_costs)
    //   .round(2)
    //   .done();
    // // price_per_sqft
    // // this.price_per_sqftTarget.value
    // var ppsqft = chain(this.purchase_price)
    //   .divide(this.total_sqft)
    //   .round(2)
    //   .done();
    // console.log("hi sqf", this.purchase_price, this.total_sqft, ppsqft);
    // this.price_per_sqftTarget.value = ppsqft;
    // // price_per_unit
    // this.price_per_unitTarget.value = chain(this.purchase_price)
    //   .divide(this.total_units)
    //   .round(2)
    //   .done();
    // // adjusted_grpi;
    // if (this.hasadjusted_grpiTarget) {
    //   this.adjusted_grpiTarget.value = chain(this.known_gpri).round(2).done();
    //   // this.adjusted_grpi_vTarget.value = chain(this.known_gpri).round(2).done();
    // }
  }

  calculate_income() {
    // adjusted_grpi;
    //this.adjusted_grpiTarget.value = chain(this.known_gpri).round(2).done();
    // this.adjusted_grpi_vTarget.value = chain(this.known_gpri).round(2).done();
    if (this.known_gpri > 0) {
      $('.income-rentroll-container').addClass('disable-card');
      this.calculated_grpiTarget.value = this.known_gpri;
    } else {
      $('.income-rentroll-container').removeClass('disable-card');
    }

    console.log('hi/s');
  }
  calculate_property_age() {
    this.actual_ageTarget.value = moment().year() - this.year_built;
  }

  calculate_income_analysis(event) {
    var gpri = $(
      '#full_evaluation_evaluation_income_expenses_attributes_0_total_amount'
    );
    var loss = $(
      '#full_evaluation_evaluation_income_expenses_attributes_1_total_amount'
    );
    var other_icnome_vacy = $(
      '#full_evaluation_evaluation_income_expenses_attributes_2_total_amount'
    );
    var effective_income = $(
      '#full_evaluation_evaluation_income_expenses_attributes_3_total_amount'
    );
    var other_income = $(
      '#full_evaluation_evaluation_income_expenses_attributes_4_total_amount'
    );
    var gross_operating_income = $('.gross_operating_income');
    var effective_income_value = chain(gpri.val())
      .subtract(loss.val())
      .subtract(other_icnome_vacy.val())
      .round(2)
      .done();
    effective_income.val(effective_income_value);

    gross_operating_income.val(
      chain(effective_income.val()).add(other_income.val()).round(2).done()
    );

    var per_square_feet_2 = $(
      '#full_evaluation_evaluation_income_expenses_attributes_2_per_square_feet'
    );
    var per_square_feet_3 = $(
      '#full_evaluation_evaluation_income_expenses_attributes_3_per_square_feet'
    );
    var per_square_feet_4 = $(
      '#full_evaluation_evaluation_income_expenses_attributes_4_per_square_feet'
    );
    per_square_feet_2.val(
      chain(other_icnome_vacy.val()).divide(this.rentable_sqft).round(2).done()
    );
    per_square_feet_3.val(
      chain(effective_income.val()).divide(this.rentable_sqft).round(2).done()
    );
    per_square_feet_4.val(
      chain(other_income.val()).divide(this.rentable_sqft).round(2).done()
    );

    var per_unit_month_2 = $(
      '#full_evaluation_evaluation_income_expenses_attributes_2_per_unit_month'
    );
    var per_unit_month_3 = $(
      '#full_evaluation_evaluation_income_expenses_attributes_3_per_unit_month'
    );
    var per_unit_month_4 = $(
      '#full_evaluation_evaluation_income_expenses_attributes_4_per_unit_month'
    );

    per_unit_month_2.val(
      chain(other_icnome_vacy.val())
        .divide(this.total_units)
        .divide(12.0)
        .round(2)
        .done()
    );
    per_unit_month_3.val(
      chain(effective_income.val())
        .divide(this.total_units)
        .divide(12.0)
        .round(2)
        .done()
    );
    per_unit_month_4.val(
      chain(other_income.val())
        .divide(this.total_units)
        .divide(12.0)
        .round(2)
        .done()
    );

    var per_month_2 = $(
      '#full_evaluation_evaluation_income_expenses_attributes_2_per_month'
    );
    var per_month_3 = $(
      '#full_evaluation_evaluation_income_expenses_attributes_3_per_month'
    );
    var per_month_4 = $(
      '#full_evaluation_evaluation_income_expenses_attributes_4_per_month'
    );

    per_month_2.val(
      chain(other_icnome_vacy.val()).divide(12.0).round(2).done()
    );
    per_month_3.val(chain(effective_income.val()).divide(12.0).round(2).done());
    per_month_4.val(chain(other_income.val()).divide(12.0).round(2).done());

    var gpri_perct_2 = $(
      '#full_evaluation_evaluation_income_expenses_attributes_2_gpri_perct'
    );
    var gpri_perct_3 = $(
      '#full_evaluation_evaluation_income_expenses_attributes_3_gpri_perct'
    );
    var gpri_perct_4 = $(
      '#full_evaluation_evaluation_income_expenses_attributes_4_gpri_perct'
    );
    // ( evaluation_income_expense.total_amount / gpri_income.total_amount ) * 100
    gpri_perct_2.val(
      chain(other_icnome_vacy.val()).divide(gpri.val()).multiply(100).done()
    );
    gpri_perct_3.val(
      chain(effective_income.val()).divide(gpri.val()).multiply(100).done()
    );
    gpri_perct_4.val(
      chain(other_income.val()).divide(gpri.val()).multiply(100).done()
    );

    // per_square_feet_3.val(chain(other_icnome_vacy.val()).divide(this.rentable_sqft).round(2).done())
    // effective_income.val
    // var total_gpri = $(
    //   "#full_evaluation_evaluation_income_expenses_attributes_0_total_amount"
    // ).val();
    // var other_income = $(
    //   "#full_evaluation_evaluation_income_expenses_attributes_2_total_amount"
    // ).val();

    // if ($(event.target).hasClass("total_amount")) {
    //   var row = $(event.target).closest(".nested-fields");
    //   var total_amount = $(event.target).val();

    //   row
    //     .find(".per_square_feet")
    //     .val(chain(total_amount).divide(this.rentable_sqft).round(2).done());

    //   row
    //     .find(".per_unit_month")
    //     .val(
    //       chain(total_amount)
    //         .divide(this.total_units)
    //         .divide(12)
    //         .round(2)
    //         .done()
    //     );

    //   row
    //     .find(".per_month")
    //     .val(chain(total_amount).divide(12).round(2).done());

    //   row
    //     .find(".gpri_perct")
    //     .val(
    //       chain(total_amount).divide(total_gpri).multiply(100).round(2).done()
    //     );
    // }

    // var total_loss = chain(total_gpri)
    //   .multiply(this.vacancy_and_credit_loss_percentage)
    //   .divide(100)
    //   .round(2)
    //   .done();
    // var effective_rental_income = total_gpri - total_loss - other_income;

    // loss row calculations
    // var loss_row = $(
    //   "#full_evaluation_evaluation_income_expenses_attributes_1_total_amount"
    // ).closest(".nested-fields");
    // loss_row.find(".total_amount").val(total_loss);
    // loss_row
    //   .find(".per_square_feet")
    //   .val(chain(total_loss).divide(this.rentable_sqft).round(2).done());
    // loss_row
    //   .find(".per_unit_month")
    //   .val(
    //     chain(total_loss).divide(this.total_units).divide(12).round(2).done()
    //   );
    // loss_row
    //   .find(".per_month")
    //   .val(chain(total_loss).divide(12).round(2).done());
    // loss_row
    //   .find(".gpri_perct")
    //   .val(chain(total_loss).divide(total_gpri).multiply(100).round(2).done());

    // effective rental income row calculations
    // var rental_income_row = $(
    //   "#full_evaluation_evaluation_income_expenses_attributes_3_total_amount"
    // ).closest(".nested-fields");

    // rental_income_row.find(".total_amount").val(effective_rental_income);
    // rental_income_row
    //   .find(".per_square_feet")
    //   .val(
    //     chain(effective_rental_income)
    //       .divide(this.rentable_sqft)
    //       .round(2)
    //       .done()
    //   );
    // rental_income_row
    //   .find(".per_unit_month")
    //   .val(
    //     chain(effective_rental_income)
    //       .divide(this.total_units)
    //       .divide(12)
    //       .round(2)
    //       .done()
    //   );
    // rental_income_row
    //   .find(".per_month")
    //   .val(chain(effective_rental_income).divide(12).round(2).done());
    // rental_income_row
    //   .find(".gpri_perct")
    //   .val(
    //     chain(effective_rental_income)
    //       .divide(total_gpri)
    //       .multiply(100)
    //       .round(2)
    //       .done()
    //   );

    // var gpi = effective_rental_income;
    // $(".total_amount:not([readonly]):not(.other_income)").each(function (
    //   i,
    //   el
    // ) {
    //   gpi = gpi + parseFloat($(el).val());
    // });
    // var gpi_row = $(
    //   "#full_evaluation_evaluation_income_expenses_attributes_4_per_square_feet"
    // ).closest(".nested-fields");

    // gpi_row.find(".total_amount").val(gpi);
    // gpi_row
    //   .find(".per_square_feet")
    //   .val(chain(gpi).divide(this.rentable_sqft).round(2).done());
    // gpi_row
    //   .find(".per_unit_month")
    //   .val(chain(gpi).divide(this.total_units).divide(12).round(2).done());
    // gpi_row.find(".per_month").val(chain(gpi).divide(12).round(2).done());
  }

  calc_by_raise_lower() {
    // adjusted_grpi;
    if (this.raise_lower_by <= 0) {
      return;
    }

    this.by_unit_monthTarget.value = 0;

    // console.log("change has been made");

    var gpri = this.calculated_grpi;
    // console.log(this.calculated_grpi);
    if (this.known_gpri != 0) {
      gpri = this.known_gpri;
    }
    // this.by_unit_monthTarget.value = 0;
    this.adjusted_grpiTarget.value = chain(gpri)
      .add(gpri * this.raise_lower_by)
      .round(2)
      .done();
  }

  calc_by_unit_month() {
    if (this.by_unit_month <= 0) {
      return;
    }
    this.raise_lower_byTarget.value = 0.0;
    // adjusted_grpi;
    // var gpri = this.calculated_grpi;
    // if (this.known_gpri != 0) {
    //   gpri = this.known_gpri;
    // }
    // fe.adjusted_grpi = (gpri.to_f + ((fe.by_unit_month.to_f * 12) * fe.units) ).round(2)
    // // this.raise_lower_byTarget.value = 0.0;
    // var total = chain(this.total_spaces)
    //   .multiply(this.by_unit_month)
    //   .multiply(12)
    //   .round(2)
    //   .done();
    // this.adjusted_grpiTarget.value = chain(gpri).add(total).round(2).done();
    // this.adjusted_grpi_vTarget.value = chain(gpri).add(total).round(2).done();
  }

  load_rent_roll() {
    console.log(this.rent_roll);
    if (this.rent_roll) {
      fetch(
        `/properties/${this.pidTarget.value}/rent_rolls/${this.rent_roll}/info?feid=${this.idTarget.value}`
      )
        .then((response) => response.json())
        .then((data) => {
          console.log(data);
          this.total_spacesTarget.value = data.rent_roll.total_spaces;
          this.economic_vacancyTarget.value = data.rent_roll.economic_vacancy;
          this.total_sqft_per_yearTarget.value =
            data.rent_roll.total_sqft_per_year;
          this.gpriTarget.value = data.rent_roll.gpri;
          this.total_camTarget.value = data.rent_roll.total_cam;
          this.total_netTarget.value = data.rent_roll.total_net;
          this.total_addlTarget.value = data.rent_roll.total_addl;
          this.total_perct_rentTarget.value = data.rent_roll.total_perct_rent;
          this.total_securityTarget.value = data.rent_roll.total_security;
          this.calculated_grpiTarget.value = data.rent_roll.gpri;
          this.vacancy_and_credit_loss_percentageTarget.value =
            data.vacancy_credit_loss_percentage;
          if (data.rent_roll.name != 'Quick Entry') {
            // this.vacancy_and_credit_loss_percentageTarget.setAttribute(
            //   'readonly',
            //   'true'
            // );
          } else {
            console.log('herenow');
            // this.vacancy_and_credit_loss_percentageTarget.setAttribute(
            //   'readonly',
            //   'false'
            // );
            this.vacancy_and_credit_loss_percentageTarget.value = 0;
          }
          var formV2Exists = document.getElementById('form_v2');
          if (formV2Exists) {
            Turbolinks.visit(window.location + "#tab2");
          } else {
            Turbolinks.visit(window.location);
          }
          this.vacant_sqft_perctTarget.value = data.rent_roll.vacant_sqft_perct;
        });
    }
  }
  calculate_summary() {
    console.log('here');
    // FIX ME : For some cases the $ is not removed this below variables which gives out an error for incompatiable/wrong conversion
    var cash_flow = chain(this.goi)
      .subtract(this.op_expense)
      .subtract(this.annual_debt_service)
      .subtract(this.capital_additions)
      .subtract(this.leasing_commisions)
      .subtract(this.funded_reserves)
      .round(2)
      .done();

    this.cash_flow_before_taxesTarget.value = cash_flow;

    // funded
    this.funded_per_sqftTarget.value = chain(this.funded_reserves)
      .divide(this.square_footage)
      .round(2)
      .done();
    this.funded_Unit_per_monthTarget.value = chain(this.funded_reserves)
      .divide(this.number_of_units)
      .divide(12)
      .round(2)
      .done();
    this.funded_per_monthTarget.value = chain(this.funded_reserves)
      .divide(12)
      .round(2)
      .done();

    // leasing commission
    this.commisions_per_sqftTarget.value = chain(this.leasing_commisions)
      .divide(this.square_footage)
      .round(2)
      .done();
    this.commisions_Unit_per_monthTarget.value = chain(this.leasing_commisions)
      .divide(this.number_of_units)
      .divide(12)
      .round(2)
      .done();
    this.commisions_per_monthTarget.value = chain(this.leasing_commisions)
      .divide(12)
      .round(2)
      .done();

    // capital
    this.capital_additions_per_sqftTarget.value = chain(this.capital_additions)
      .divide(this.square_footage)
      .round(2)
      .done();
    this.capital_additions_Unit_per_monthTarget.value = chain(
      this.capital_additions
    )
      .divide(this.number_of_units)
      .divide(12)
      .round(2)
      .done();
    this.capital_additions_per_monthTarget.value = chain(this.capital_additions)
      .divide(12)
      .round(2)
      .done();

    // cash_flow_before_taxes
    this.cash_flow_per_sqftTarget.value = chain(cash_flow)
      .divide(this.square_footage)
      .round(2)
      .done();
    this.cash_flow_Unit_per_monthTarget.value = chain(cash_flow)
      .divide(this.number_of_units)
      .divide(12)
      .round(2)
      .done();
    console.log(cash_flow / 12);
    this.cash_flow_per_monthTarget.value = chain(cash_flow)
      .divide(12)
      .round(2)
      .done();
  }

  calculate_summary_what_ifs() {
    var noi = chain(this.goi).subtract(this.op_expense).round(2).done();
    this.w2_valueTarget.value = chain(noi)
      .divide(this.w1_cap_rate / 100)
      .round(2)
      .done();
    this.w3_sale_cash_out_pre_taxTarget.value =
      this.w2_value - this.mortgages_amount;
    this.w5_re_fi_proceedsTarget.value = chain(this.w2_value)
      .multiply(this.w4_re_fi_prect / 100)
      .round(2)
      .done();
    this.w6_re_fi_cash_outTarget.value = chain(this.w5_re_fi_proceeds)
      .subtract(this.mortgages_amount)
      .round(2)
      .done();

    console.log(noi, this.annual_debt_service_primary);

    this.dcr_b_42Target.value = chain(noi)
      .divide(this.annual_debt_service_primary)
      .round(2)
      .done();

    this.dcr_i_43Target.value = chain(noi)
      .divide(this.annual_debt_service)
      .round(2)
      .done();

    this.cap_rate_44Target.value = chain(noi)
      .divide(this.purchase_price)
      .multiply(100)
      .round(2)
      .done();

    this.c_on_c_45Target.value = chain(this.cash_flow_before_taxes)
      .divide(this.initial_investment)
      .multiply(100)
      .round(2)
      .done();

    if (this.w5_re_fi_proceeds != '') {
      $.get(
        '/calc_periodic_payment',
        {
          apr: this.re_fi_int_rate / 100.0,
          initial_loan: this.w5_re_fi_proceeds,
          duration: this.term_years * 12,
          interest_only: false,
        },
        (data) => {
          var dcrb = chain(noi)
            .divide(data.periodic_payment * 12)
            .round(8)
            .done();
          if (!isFinite(dcrb)) {
            dcrb = 0;
          }
          this.dcr_bTarget.value = dcrb;
        }
      );
    }

    // this.dcr_bTarget.value = chain(noi)
    //   .divide(this.annual_debt_service)
    //   .round(2)
    //   .done();

    var gpri = this.goi;

    if (this.known_gpri != 0) {
      gpri = this.known_gpri;
    }

    this.gross_rent_multiplierTarget.value = chain(gpri)
      .divide(this.purchase_price)
      .multiply(100)
      .round(2)
      .done();
  }

  calculate_loan_value() {
    console.log(this.loan_to_valueTarget);
    if (this.loan_purchase_priceTarget.value > 0) {
      var loan_to_value = chain(this.initial_loanTarget.value)
        .divide(this.loan_purchase_priceTarget.value)
        .multiply(100)
        .done();
      this.loan_to_value_realTarget.value = loan_to_value;
      this.loan_to_valueTarget.value = loan_to_value.toFixed(2);
    }
    this.calculate_periodic_payment();
  }

  calculate_initial_loan() {
    if (this.loan_purchase_priceTarget.value > 0) {
      this.loan_to_value_realTarget.value = this.loan_to_valueTarget.value;
      this.initial_loanTarget.value = chain(
        this.loan_purchase_priceTarget.value
      )
        .multiply(this.loan_to_value_realTarget.value)
        .divide(100)
        .round(2)
        .done();
    }
  }

  calculate_periodic_payment() {
    var amoritization_period_years = this.amoritization_period_years;
    if (amoritization_period_years == '' || amoritization_period_years == 0) {
      amoritization_period_years = 1;
    }
    $.get(
      '/calc_periodic_payment',
      {
        apr: this.interest_rate / 100.0,
        initial_loan: this.initial_loanTarget.value,
        duration: this.payments_year * amoritization_period_years,
        interest_only: this.interest_only,
      },
      (data) => {
        console.log(data);
        if (this.interest_only) {
          this.periodic_paymentTarget.value = data.interest_only;
        } else {
          this.periodic_paymentTarget.value = data.periodic_payment;
        }
      }
    );

    // if (this.interest_only) {
    //   // (Initial Loan * Interest Rate / Payments Year)
    //   this.periodic_paymentTarget.value = chain(this.initial_loanTarget.value)
    //     .multiply(this.interest_rate / 100)
    //     .divide(this.payments_year)
    //     .round(2)
    //     .done();
    // } else {
    //   // Initial Loan / (Interest Rate / Payments Year) / (Payments Year * Amoritization Period Years)
    //   this.periodic_paymentTarget.value = chain(this.initial_loanTarget.value)
    //     .multiply(this.interest_rate / this.payments_year)
    //     .divide(this.payments_year * this.amoritization_period_years)
    //     .round(2)
    //     .done();
    // }
  }

  calculate_operating_expenses() {
    if ($(event.target).hasClass('total_amount')) {
      var row = $(event.target).closest('.nested-fields');
      var total_amount = $(event.target).val();
      var per_square_feet = row.find('.per_square_feet').val();

      row
        .find('.per_square_feet')
        .val(chain(total_amount).divide(this.rentable_sqft).round(2).done());

      row
        .find('.per_unit_month')
        .val(
          chain(total_amount)
            .divide(this.total_units)
            .divide(12)
            .round(2)
            .done()
        );

      row
        .find('.per_month')
        .val(chain(total_amount).divide(12).round(2).done());

      row
        .find('.per_unit')
        .val(chain(total_amount).divide(this.total_units).round(2).done());

      row
        .find('.gop_percentage')
        .val(
          chain(total_amount).divide(this.goi).multiply(100).round(2).done()
        );

      row
        .find('.dollar_sqft_per_month')
        .val(
          chain(total_amount)
            .divide(this.rentable_sqft)
            .divide(12)
            .round(2)
            .done()
        );
    }

    // START: TOTAL OPERATING EXPENSES calculations
    var op_expense = 0;
    $('.exp_total_amount').each(function (i, el) {
      var amount = parseFloat($(el).val());
      console.log(amount);
      if ($.isNumeric(amount)) {
        op_expense = op_expense + amount;
      }
    });

    // calculate % Op Expense
    var total_percentage_op_expense = 0;
    $('.total_amount').each(function (i, el) {
      var amount = parseFloat($(el).val());
      if ($.isNumeric(amount)) {
        row = $(el).closest('.nested-fields');
        var percentage_op_expense = chain(amount)
          .divide(op_expense)
          .multiply(100)
          .round(2)
          .done();
        total_percentage_op_expense += percentage_op_expense;
        row.find('.operational_expense_percentage').val(percentage_op_expense);
      }
    });

    var op_expense_row = $('#finalrow_expense');
    op_expense_row.find('.total_operating_expense').val(op_expense);
    op_expense_row
      .find('.total_per_square_feet')
      .val(chain(op_expense).divide(this.rentable_sqft).round(2).done());

    op_expense_row
      .find('.total_per_unit_month')
      .val(
        chain(op_expense).divide(this.total_units).divide(12).round(2).done()
      );
    op_expense_row
      .find('.total_per_month')
      .val(chain(op_expense).divide(12).round(2).done());

    op_expense_row
      .find('.total_gop_percentage')
      .val(chain(op_expense).divide(this.goi).multiply(100).round(2).done());
    op_expense_row
      .find('.total_operational_expense_percentage')
      .val(total_percentage_op_expense);
    // END: TOTAL OPERATING EXPENSES calculations
  }

  calculate_due_diligence_expenses() {
    var total_expense = 0;

    if (this.due_diligence_type == 'Exterior') {
      $('.estimated_expense:not(.field-125)').each(function (i, el) {
        var amount = parseFloat($(el).val());
        if ($.isNumeric(amount)) {
          total_expense = total_expense + amount;
        }
      });
      $('.field-125').val(total_expense);
    }
    if (this.due_diligence_type == 'Interior') {
      $('.estimated_expense:not(.field-291)').each(function (i, el) {
        var amount = parseFloat($(el).val());
        if ($.isNumeric(amount)) {
          total_expense = total_expense + amount;
        }
      });
      $('.field-291').val(total_expense);
    }
    if (this.due_diligence_type == 'Common Area') {
      $('.estimated_expense:not(.field-157)').each(function (i, el) {
        var amount = parseFloat($(el).val());
        if ($.isNumeric(amount)) {
          total_expense = total_expense + amount;
        }
      });
      $('.field-157').val(total_expense);
    }
  }

  get purchase_price() {
    return this.purchase_priceTarget.value;
  }
  get mortgages() {
    return this.mortgagesTarget.value;
  }
  get lot_points() {
    return this.lot_pointsTarget.value;
  }
  get acquisition_costs() {
    return this.acquisition_costsTarget.value;
  }
  get initial_investment() {
    return this.initial_investmentTarget.value;
  }
  get price_per_sqft() {
    return this.price_per_sqftTarget.value;
  }
  get price_per_unit() {
    return this.price_per_unitTarget.value;
  }
  get total_units() {
    return this.total_unitsTarget.value;
  }
  get total_sqft() {
    return this.total_sqftTarget.value;
  }

  get known_gpri() {
    return this.known_gpriTarget.value;
  }
  get raise_lower_by() {
    return chain(this.raise_lower_byTarget.value).divide(100).round(2).done();
  }
  get by_unit_month() {
    return this.by_unit_monthTarget.value;
  }

  get adjusted_grpi() {
    return this.adjusted_grpiTarget.value;
  }

  get rent_roll() {
    return this.rent_rollTarget.value;
  }
  get rentable_sqft() {
    return this.rentable_sqftTarget.value;
  }
  get total_amount() {
    return this.total_amountTarget.value;
  }
  get per_square_feet() {
    return this.per_square_feetTarget.value;
  }
  get per_unit_month() {
    return this.per_unit_monthTarget.value;
  }
  get per_month() {
    return this.per_monthTarget.value;
  }
  get per_unit() {
    return this.per_unitTarget.value;
  }
  get payments_year() {
    return this.payments_yearTarget.value;
  }
  get interest_rate() {
    return this.interest_rateTarget.value;
  }
  get amoritization_period_years() {
    return this.amoritization_period_yearsTarget.value;
  }
  get term_years() {
    return this.term_yearsTarget.value;
  }
  get interest_only() {
    return $(this.interest_onlyTarget).is(':checked');
  }
  get goi() {
    return this.goiTarget.value;
  }
  get vacancy_and_credit_loss_percentage() {
    return this.vacancy_and_credit_loss_percentageTarget.value;
  }
  get loan_to_value() {
    return this.loan_to_valueTarget.value;
  }
  get year_built() {
    return this.year_builtTarget.value;
  }
  get estimated_expense() {
    return this.estimated_expenseTarget.value;
  }
  get due_diligence_type() {
    return this.due_diligence_typeTarget.value;
  }
  get total_spaces() {
    return this.total_spacesTarget.value;
  }
  get calculated_grpi() {
    return this.calculated_grpiTarget.value;
  }
  get gpri_perct() {
    return this.gpri_perctTarget.value;
  }
  get op_expense() {
    return this.op_expenseTarget.value;
  }
  get annual_debt_service() {
    return this.annual_debt_serviceTarget.value;
  }
  get annual_debt_service_primary() {
    return this.annual_debt_service_primaryTarget.value;
  }
  get funded_reserves() {
    return this.funded_reservesTarget.value;
  }
  get leasing_commisions() {
    return this.leasing_commisionsTarget.value;
  }
  get capital_additions() {
    return this.capital_additionsTarget.value;
  }
  get square_footage() {
    return this.square_footageTarget.value;
  }
  get number_of_units() {
    return this.number_of_unitsTarget.value;
  }
  get w1_cap_rate() {
    return this.w1_cap_rateTarget.value;
  }

  get w2_value() {
    return this.w2_valueTarget.value;
  }
  get w3_sale_cash_out_pre_tax() {
    return this.w3_sale_cash_out_pre_taxTarget.value;
  }
  get w4_re_fi_prect() {
    return this.w4_re_fi_prectTarget.value;
  }
  get w5_re_fi_proceeds() {
    return this.w5_re_fi_proceedsTarget.value;
  }
  get w6_re_fi_cash_out() {
    return this.w6_re_fi_cash_outTarget.value;
  }
  get re_fi_int_rate() {
    return this.re_fi_int_rateTarget.value;
  }
  get re_fi_am_pd() {
    return this.re_fi_am_pdTarget.value;
  }
  get term_years() {
    return this.term_yearsTarget.value;
  }
  get dcr_b() {
    return this.dcr_bTarget.value;
  }
  get dcr_b_42() {
    return this.dcr_b_42Target.value;
  }
  get dcr_i_43() {
    return this.dcr_i_43Target.value;
  }
  get cap_rate_44() {
    return this.cap_rate_44Target.value;
  }
  get c_on_c_45() {
    return this.c_on_c_45Target.value;
  }
  get gross_rent_multiplier() {
    return this.gross_rent_multiplierTarget.value;
  }
  get mortgages_amount() {
    return this.mortgages_amountTarget.value;
  }
  get loan_to_value_real() {
    return this.loan_to_value_realTarget.value;
  }
  get cash_flow_before_taxes() {
    return this.cash_flow_before_taxesTarget.value;
  }
}
