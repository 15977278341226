import Inputmask from 'inputmask';

$(document).on('turbolinks:load', function () {
  if (typeof openNewModal !== 'undefined' && openNewModal == true) {
    laodModal(newModalUrl);
    openNewModal = false;
  }
  $(document).on('click', 'a[data-modal]', function () {
    laodModal($(this).attr('href'));
    return false;
  });

  $(document).on('ajax:success', 'form[data-modal]', function (event) {
    const [data, _status, xhr] = event.detail;
    const url = xhr.getResponseHeader('Location');
    console.log(url);
    if (url) {
      // Redirect to url
      window.location = url;
    } else {
      // Remove old modal backdrop
      $('.modal-backdrop').remove();
      // Update modal content
      const modal = $(data).find('body').html();
      $(modal_holder_selector).html(modal).find(modal_selector).modal();
    }

    return false;
  });
  $(document).on('hidden.bs.modal', '#mainModal', function (e) {
    $('.modal-backdrop').remove();
  });
});

function laodModal(location) {
  const modal_holder_selector = '#modal-holder';
  const modal_selector = '.modal';

  // const location = ;
  // Load modal dialog from server
  $.get(location, (data) => {
    $(modal_holder_selector).html(data).find(modal_selector).modal();
    flatpickr('.datepicker', {
      altInput: true,
      altFormat: 'm/d/Y',
      dateFormat: 'm/d/Y',
      static: true,
      allowInput: true,
    });
    $('.summernote').summernote();
    flatpickr('.datetimepicker', {
      enableTime: true,
      dateFormat: 'm/d/Y H:i',
    });
    $('#new_unit').validate({});
    $('.report_select').on('change', function () {
      var type = $(this).val();
      // if()
      if (type == 'Financial') {
        $('#quick_check').prop('checked', $(this).is(':checked'));
        $('#full_evaluation').prop('checked', $(this).is(':checked'));
        $('#future_valuations').prop('checked', $(this).is(':checked'));
        $('#rent_roll').prop('checked', $(this).is(':checked'));
        $('#investment_profile').prop('checked', $(this).is(':checked'));
        $('#seller_profile').prop('checked', $(this).is(':checked'));
        $('#project_funding_profile').prop('checked', $(this).is(':checked'));
        $('#property_background').prop('checked', $(this).is(':checked'));
        $('#edd').prop('checked', !$(this).is(':checked'));
        $('#idd').prop('checked', !$(this).is(':checked'));
        $('#fdd').prop('checked', !$(this).is(':checked'));
        $('#cadd').prop('checked', !$(this).is(':checked'));
        $('#cadd').prop('checked', !$(this).is(':checked'));
        $('.comp').prop('checked', false);
      } else {
        $('.fin').prop('checked', false);
        $('#edd').prop('checked', $(this).is(':checked'));
        $('#idd').prop('checked', $(this).is(':checked'));
        $('#fdd').prop('checked', $(this).is(':checked'));
        $('#cadd').prop('checked', $(this).is(':checked'));
        $('#quick_check').prop('checked', $(this).is(':checked'));
        $('#full_evaluation').prop('checked', $(this).is(':checked'));
        $('#future_valuations').prop('checked', $(this).is(':checked'));
        $('#rent_roll').prop('checked', $(this).is(':checked'));
        $('#investment_profile').prop('checked', $(this).is(':checked'));
        $('#seller_profile').prop('checked', $(this).is(':checked'));
        $('#project_funding_profile').prop('checked', $(this).is(':checked'));
        $('#property_background').prop('checked', $(this).is(':checked'));
        $('#property_background').prop('checked', $(this).is(':checked'));
      }
      console.log('hello');
    });
    // var currencyMask = IMask(document.getElementById("currency"), {
    Inputmask({
      alias: 'currency',
      prefix: '$',
      digitsOptional: true,
      autoUnmask: true,
      removeMaskOnSubmit: true,
      clearMaskOnLostFocus: false,
      digits: 0,
      positionCaretOnClick: 'select',
    }).mask($('.currency'));

    Inputmask({
      alias: 'currency',
      prefix: '$',
      digitsOptional: true,
      autoUnmask: true,
      removeMaskOnSubmit: true,
      clearMaskOnLostFocus: false,
      digits: 2,
      // positionCaretOnClick: 'select',
    }).mask($('.currency_with_decimals_right'));

    Inputmask({
      autoUnmask: true,
      alias: 'decimal',
      groupSeparator: ',',
      digits: 2,
      digitsOptional: true,
      removeMaskOnSubmit: true,
      clearMaskOnLostFocus: false,
      // positionCaretOnClick: 'select',
    }).mask($('.decimal'));
    Inputmask({
      autoUnmask: true,
      alias: 'decimal',
      suffix: '%',
      rightAlign: true,
      groupSeparator: ',',
      digits: 2,
      digitsOptional: true,
      removeMaskOnSubmit: true,
      clearMaskOnLostFocus: false,
      rightAlign: true,
      // positionCaretOnClick: 'select',
    }).mask($('.decimal_percnt'));
    Inputmask({
      autoUnmask: true,
      alias: 'integer',
      groupSeparator: ',',
      removeMaskOnSubmit: true,
      clearMaskOnLostFocus: false,
      // positionCaretOnClick: 'select',
    }).mask($('.number'));
  });
  return false;
}
