import { chain, evaluate } from "mathjs";
function debounce(func, wait, immediate) {
  var timeout;
  return function () {
    var context = this,
      args = arguments;
    var later = function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    var callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
}
$(document).on("turbolinks:load", function () {
  var calculate_url = $("#calculate-url").data("url");
  $(
    ".calculate, .operating_expenses_calc, .operating_expenses_percentage_calc"
  ).keyup(
    debounce(function () {
      var $this = $(this);
      var category_id = $(this).data("category");
      var purchase_price = $(".purchase_price_" + category_id);
      var sq_ft = $(".sq_ft_" + category_id);
      var gross_potential_rental_income = $(
        ".gross_potential_rental_income_" + category_id
      );
      var vacancy_credit_loss = $(".vacancy_credit_loss_" + category_id);
      var additional_income = $(".additional_income_" + category_id);
      var cam_recapture = $(".cam_recapture_" + category_id);
      var operating_expenses = $(".operating_expenses_" + category_id);
      var operating_expenses = $(".operating_expenses_" + category_id);
      var operating_expenses_percentage = $(
        ".operating_expenses_percentage_" + category_id
      );
      var cap_rate_desired = $(".cap_rate_desired_" + category_id);
      var reverse = "";
      if ($this.hasClass("operating_expenses_cookie")) {
        reverse = "percentage";
      }
      if ($this.hasClass("operating_expenses_percentage_cookie")) {
        reverse = "value";
      }
      $.ajax({
        type: "GET",
        url: calculate_url,
        // data: form.serialize(),
        data: {
          category_id: category_id,
          purchase_price: purchase_price.val(),
          sq_ft: sq_ft.val(),
          gross_potential_rental_income: gross_potential_rental_income.val(),
          vacancy_credit_loss: vacancy_credit_loss.val(),
          additional_income: additional_income.val(),
          cam_recapture: cam_recapture.val(),
          operating_expenses: operating_expenses.val(),
          operating_expenses_percentage: operating_expenses_percentage.val(),
          cap_rate_desired: cap_rate_desired.val(),
          reverse: reverse,
        },
        success: function (data) {
          //console.log("daaaaaaaaaaataaaaaa");
          //console.log(data);
          $(".gross_operating_income_" + category_id).val(
            data.result.gross_operating_income
          );
          $(".net_operating_income_" + category_id).val(
            data.result.net_operating_income
          );
          $(".cap_rate_actual_" + category_id).val(data.result.cap_rate_actual);
          $(".price_per_sqft_html_" + category_id).html(
            "$" + data.result.price_per_sqft
          );
          $(".price_per_sqft_" + category_id).val(data.result.price_per_sqft);
          $(".value_per_sqft_html_" + category_id).html(
            "$" + data.result.value_per_sqft
          );
          $(".value_per_sqft_" + category_id).val(data.result.value_per_sqft);
          $(".value_" + category_id).val(data.result.value);
          if (reverse == "percentage") {
            $(".operating_expenses_percentage_" + category_id).val(
              data.result.operating_expenses_percentage_round
            );
            $(".operating_expenses_percentage_real_" + category_id).val(
              data.result.operating_expenses_percentage
            );
          }
          if (reverse == "value") {
            console.log("hello", data.result.operating_expenses);
            $(".operating_expenses_" + category_id).val(
              data.result.operating_expenses
            );
          }
          if (data.result.value_per_sqft < data.result.price_per_sqft) {
            $(".value_per_sqft_html_" + category_id)
              .removeClass("text-success")
              .addClass("text-danger");
          } else {
            $(".value_per_sqft_html_" + category_id)
              .removeClass("text-danger")
              .addClass("text-success");
          }

          if (data.result.value < purchase_price.val()) {
            $(".value_" + category_id)
              .removeClass("text-success")
              .addClass("text-danger");
          } else {
            $(".value_" + category_id)
              .removeClass("text-danger")
              .addClass("text-success");
          }
          var form = $(".evaluation_form");
          var url = form.attr("action");
          var method = form.attr("method");
          $.ajax({
            type: method,
            url: url,
            data: form.serialize(),
            success: function (data) {
              console.log("saved");
            },
            dataType: "JSON",
          });
        },
        dataType: "JSON",
      });
    }, 500)
  );
});
