var marketSumnmry = (function () {
  var incomeChart = function () {
    var chartData = {
      labels: [
        '0-$14,999',
        '$15K-$24,999',
        '$25K-$34,999',
        '$35K-$49,999',
        '$50K-$74,999',
        '$75K-$99,999',
        '$100K-$124,999',
        '$125K-$149,999',
        '$150K-$199,999',
        '$200K+',
      ],
      datasets: [
        {
          label: '% of households by income bracket',
          borderWidth: 1,
          backgroundColor: '#51857b',
          data: $('#income_data').data('array'),
        },
      ],
    };

    var chartContainer = $('#income_chart');
    if (chartContainer.length == 0) {
      return;
    }
    var myBarChart = new Chart(chartContainer, {
      type: 'bar',
      data: chartData,
      options: {
        legend: { position: 'bottom' },
        tooltips: {
          callbacks: {
            label: function (tooltipItem, data) {
              return tooltipItem.yLabel + '% of the population.';
            },
          },
        },
      },
    });
    chartContainer.height = 200;
  };
  var populationChart = function () {
    var chartData = {
      labels: ['1990', '2000', '2010'],
      datasets: [
        {
          label: 'Population Trend',
          fill: false,
          data: $('#population_data').data('array'),
        },
      ],
    };

    var chartContainer = $('#population_chart');
    if (chartContainer.length == 0) {
      return;
    }
    var myBarChart = new Chart(chartContainer, {
      type: 'line',
      data: chartData,
      options: {
        legend: { position: 'bottom' },
      },
    });
    chartContainer.height = 200;
  };

  var ageChart = function () {
    var chartData = {
      labels: [
        '5 and under',
        '6-11',
        '12-17',
        '18-24',
        '25-34',
        '35-44',
        '45-54',
        '55-64',
        '65-74',
        '75-84',
        '85+',
      ],

      datasets: [
        {
          label: '% of Population by Age',
          backgroundColor: [
            '#846525',
            '#765a21',
            '#937029',
            '#9e7e3e',
            '#a98d54',
            '#b39b69',
            '#bea97f',
            '#c9b894',
            '#d4c6a9',
            '#dfd4bf',
            '#e9e2d4',
          ],
          fill: false,
          data: $('#age_data').data('array'),
        },
      ],
    };

    var chartContainer = $('#age_chart');
    if (chartContainer.length == 0) {
      return;
    }
    var myBarChart = new Chart(chartContainer, {
      type: 'bar',
      data: chartData,
      options: {
        legend: { position: 'bottom' },
        tooltips: {
          callbacks: {
            label: function (tooltipItem, data) {
              return tooltipItem.yLabel + '% of the population.';
            },
          },
        },
      },
    });
    chartContainer.height = 200;
  };

  var gendrChart = function () {
    var chartData = {
      labels: ['Male', 'Female'],
      datasets: [
        {
          label: '% of Population by Gender',
          backgroundColor: ['#004c6d', '#51857b'],
          data: $('#gender_data').data('array'),
        },
      ],
    };

    var chartContainer = $('#gender_chart');
    if (chartContainer.length == 0) {
      return;
    }
    var myBarChart = new Chart(chartContainer, {
      type: 'pie',
      data: chartData,
      options: { legend: { position: 'bottom' } },
    });
    chartContainer.height = 100;
  };

  var householdOccupancyChart = function () {
    var chartData = {
      labels: ['Rented', 'Owned', 'Vacant'],
      datasets: [
        {
          label: '% of Population by Gender',
          backgroundColor: ['#2E4057', '#51857b', '#E0D8DE'],
          data: $('#household_occupancy1').data('array'),
        },
      ],
    };

    var chartContainer = $('#households_chart1');
    if (chartContainer.length == 0) {
      return;
    }
    var myBarChart = new Chart(chartContainer, {
      type: 'pie',
      data: chartData,
      options: {
        legend: { position: 'bottom' },
        title: {
          display: true,
          text: 'Household ownership',
          fontSize: 16,
        },
      },
    });
    chartContainer.height = 200;
  };

  var costChart = function () {
    var chartData = {
      labels: [
        'Overall',
        'Apparel',
        'Education',
        'Entertainment',
        'Food',
        'Healthcare',
        'Housing',
        'Transportation',
        'Utilities',
      ],
      datasets: [
        {
          label: 'Cost of Items Related the National Average',
          backgroundColor: [
            '#937029',
            '#A7A2A9',
            '#A7A2A9',
            '#A7A2A9',
            '#A7A2A9',
            '#A7A2A9',
            '#A7A2A9',
            '#A7A2A9',
            '#A7A2A9',
            '#A7A2A9',
            '#A7A2A9',
          ],
          data: $('#costs_data').data('array'),
        },
      ],
    };

    var chartContainer = $('#costs_chart');
    if (chartContainer.length == 0) {
      return;
    }
    var myBarChart = new Chart(chartContainer, {
      type: 'horizontalBar',
      data: chartData,
      options: {
        legend: { position: 'bottom' },
        elements: {
          rectangle: {
            borderWidth: 2,
          },
        },
        scales: {
          xAxes: [
            {
              ticks: {
                // Include a dollar sign in the ticks
                callback: function (value, index, values) {
                  if (value == 0) {
                    return 'National Average';
                  } else {
                    return value + '%';
                  }
                },
              },
            },
          ],
        },
        tooltips: {
          callbacks: {
            label: function (tooltipItem, data) {
              return tooltipItem.xLabel + '% compared to the national average.';
            },
          },
        },
      },
    });
    chartContainer.height = 200;
  };

  var crimeChart = function () {
    var chartData = {
      labels: [
        'Overall',
        'Assault',
        'Burglary',
        'Larceny',
        'Murder',
        'Rape',
        'Robbery',
        'Vehicle',
      ],
      datasets: [
        {
          label: 'Crime Related the National Average',
          backgroundColor: [
            '#937029',
            '#A7A2A9',
            '#A7A2A9',
            '#A7A2A9',
            '#A7A2A9',
            '#A7A2A9',
            '#A7A2A9',
            '#A7A2A9',
            '#A7A2A9',
            '#A7A2A9',
            '#A7A2A9',
          ],
          data: $('#crime_data').data('array'),
        },
      ],
    };

    var chartContainer = $('#crime_chart');
    if (chartContainer.length == 0) {
      return;
    }
    var myBarChart = new Chart(chartContainer, {
      type: 'horizontalBar',
      data: chartData,
      options: {
        legend: { position: 'bottom' },
        elements: {
          rectangle: {
            borderWidth: 2,
          },
        },
        scales: {
          xAxes: [
            {
              ticks: {
                // Include a dollar sign in the ticks
                callback: function (value, index, values) {
                  if (value == 0) {
                    return 'National Average';
                  } else {
                    return value + '%';
                  }
                },
              },
            },
          ],
        },
        tooltips: {
          callbacks: {
            label: function (tooltipItem, data) {
              return tooltipItem.xLabel + '% compared to national average.';
            },
          },
        },
      },
    });
    chartContainer.height = 200;
  };

  var educationChart = function () {
    var chartData = {
      labels: [
        'High School Diploma',
        "Associate's Degree",
        "Bachelor's Degree",
        'Graduate Degree',
      ],

      datasets: [
        {
          label: 'Percent of Population that Holds a Degree',
          backgroundColor: [
            '#bea97f',
            '#bea97f',
            '#a98d54',
            '#bea97f',
            '#b39b69',
          ],
          fill: false,
          data: $('#education_data').data('array'),
        },
      ],
    };

    var chartContainer = $('#education_chart');
    if (chartContainer.length == 0) {
      return;
    }
    var myBarChart = new Chart(chartContainer, {
      type: 'bar',
      data: chartData,
      options: {
        legend: { position: 'bottom' },
        tooltips: {
          callbacks: {
            label: function (tooltipItem, data) {
              return tooltipItem.yLabel + '%';
            },
          },
        },
        tooltips: {
          callbacks: {
            label: function (tooltipItem, data) {
              return tooltipItem.yLabel + '% of the population.';
            },
          },
        },
      },
    });
    chartContainer.height = 200;
  };

  var race1Chart = function () {
    var chartData = {
      labels: ['white', 'black', 'asian', 'native', 'other'],
      datasets: [
        {
          label: '% of Population by Race',
          backgroundColor: [
            '#004c6d',
            '#937029',
            '#A7A2A9',
            '#065143',
            '#474044',
          ],
          data: $('#race1_data').data('array'),
        },
      ],
    };

    var chartContainer = $('#race_chart1');
    if (chartContainer.length == 0) {
      return;
    }
    var myBarChart = new Chart(chartContainer, {
      type: 'pie',
      data: chartData,
      options: {
        legend: { position: 'bottom' },
        title: {
          display: true,
          text: 'Population Breakdown by Race',
          fontSize: 16,
        },
      },
    });
    chartContainer.height = 200;
  };

  var race2Chart = function () {
    var chartData = {
      labels: ['Hispanic', 'Other'],
      datasets: [
        {
          label: '% of Population by Gender',
          backgroundColor: ['#937029', '#004c6d'],
          data: $('#race2_data').data('array'),
        },
      ],
    };

    var chartContainer = $('#race_chart2');
    if (chartContainer.length == 0) {
      return;
    }
    var myBarChart = new Chart(chartContainer, {
      type: 'pie',
      data: chartData,
      options: {
        legend: { position: 'bottom' },
        title: {
          display: true,
          text: 'Percentage of Hispanic Population',
          fontSize: 16,
        },
      },
    });
    chartContainer.height = 200;
  };

  var weatherIndicesChart = function () {
    var chartData = {
      labels: [
        'Overall',
        'Earthquake',
        'Hail',
        'Hurricane',
        'Overall',
        'Ozone',
        'Pollution',
        'Tornado',
        'Wind',
      ],
      datasets: [
        {
          label: 'Value Compared to the National Average',
          backgroundColor: [
            '#937029',
            '#A7A2A9',
            '#A7A2A9',
            '#A7A2A9',
            '#A7A2A9',
            '#A7A2A9',
            '#A7A2A9',
            '#A7A2A9',
            '#A7A2A9',
            '#A7A2A9',
            '#A7A2A9',
          ],
          data: $('#weather_indices_data').data('array'),
        },
      ],
    };

    var chartContainer = $('#weather_indices_chart');
    if (chartContainer.length == 0) {
      return;
    }
    var myBarChart = new Chart(chartContainer, {
      type: 'horizontalBar',
      data: chartData,
      options: {
        legend: { position: 'bottom' },
        elements: {
          rectangle: {
            borderWidth: 2,
          },
        },
        scales: {
          xAxes: [
            {
              ticks: {
                // Include a dollar sign in the ticks
                callback: function (value, index, values) {
                  if (value == 0) {
                    return 'National Average';
                  } else {
                    return value + '%';
                  }
                },
              },
            },
          ],
        },
        tooltips: {
          callbacks: {
            label: function (tooltipItem, data) {
              return tooltipItem.xLabel + '% compared to national average.';
            },
          },
        },
      },
    });
    chartContainer.height = 200;
  };

  return {
    init: function () {
      incomeChart();
      populationChart();
      ageChart();
      gendrChart();
      householdOccupancyChart();
      costChart();
      crimeChart();
      educationChart();
      race1Chart();
      race2Chart();
      weatherIndicesChart();
    },
  };
})();
$(document).on('turbolinks:load', function () {
  marketSumnmry.init();
});
