// Visit The Stimulus Handbook for more details
// https://stimulusjs.org/handbook/introduction
//
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "stimulus";
import { chain, evaluate } from "mathjs";
export default class extends Controller {
  static targets = [
    "lease_sqft",
    "annual_base_rent_sqft",
    "rentable_sqft",
    "percentage_rentable_space",
    "monthly_rent_income",
    "annual_gross_rent",
    "actual_cam_recapture",
    "total_annual_rent",
    "estimated_annual_expense",
    "estimated_annual_expense_recapture",
    "total_annual_expenses",
    "annual_gross_income",
    "total_gross_rent",
    "term_years",
    "tenant_imporovement_costs_sqft",
    "tenant_improvement_costs_total",
    "lease_type",
    "total_cost_aquire_tenant",
    "broker_fees",
    "rent_buyouts",
    "other_fees",
    "increased_property_value",
    "refinance",
    "cash_out_refinance",
    "w2_increased_value",
    "total_rent_base_lease_pct",
    "w1_cap_rate",
    "w4_cash_out_new_lease",
    "w3_refinance",
    "w5_cash_out_one_time",
    "w7_breakeven_lease_months",
    "total_time_costs",
    "w2_increased_value_x",
    "w1_cap_rate_x",
    "entry_year",
    "summary_analyses_id",
    "w3_refinance_x",
    "w4_cash_out_new_lease_x",
    "w5_cash_out_one_time_x",
    "total_cost_lease",
    "annual_lease_cost",
    "total_income_buyout_costs",
    "year_label",
    "broker_commision",
    "total_broker_fee",
    "broker_term_years",
    "broker_annual_gross_rent",
    "cash_out_refinance_costs",
  ];
  calculate() {
    console.log(this.lease_sqft, "hello");

    this.percentage_rentable_spaceTarget.value = chain(this.lease_sqft)
      .divide(this.rentable_sqft)
      .multiply(100)
      .round(2)
      .done();
    this.monthly_rent_incomeTarget.value = chain(this.lease_sqft)
      .multiply(this.annual_base_rent_sqft)
      .divide(12)
      .round(2)
      .done();

    this.annual_gross_rentTarget.value = chain(this.lease_sqft)
      .multiply(this.annual_base_rent_sqft)
      .round(2)
      .done();

    this.total_annual_rentTarget.value = chain(this.annual_gross_rent)
      .add(this.actual_cam_recapture)
      .round(2)
      .done();

    this.estimated_annual_expenseTarget.value = chain(
      this.percentage_rentable_space
    )
      .multiply(this.total_annual_expenses)
      .divide(100)
      .round(2)
      .done();

    if (this.lease_type == "Absolute Net") {
      this.estimated_annual_expense_recaptureTarget.value = chain(
        this.percentage_rentable_space
      )
        .divide(100)
        .multiply(this.total_annual_expenses)
        .round(2)
        .done();
    } else {
      this.estimated_annual_expense_recaptureTarget.value = 0;
    }

    if (this.lease_type == "Absolute Net") {
      this.annual_gross_incomeTarget.value = chain(this.annual_gross_rent)
        .add(this.estimated_annual_expense_recaptureTarget.value)
        .round(2)
        .done();
    } else {
      this.annual_gross_incomeTarget.value = chain(this.annual_gross_rent)
        .round(2)
        .done();
    }

    this.total_gross_rentTarget.value = chain(this.annual_gross_income)
      .multiply(this.term_years)
      .round(2)
      .done();
  }

  calculate_one_time(e) {
    var broker_fees_total = chain(this.broker_fees)
      .divide(100)
      .multiply(this.annual_gross_rent)
      .multiply(this.term_years)
      .round(2)
      .done();
    // console.log(broker_fees_total);

    if (e.target.name == "lease_quick_check[tenant_imporovement_costs_sqft]") {
      this.tenant_improvement_costs_totalTarget.value = chain(
        this.tenant_imporovement_costs_sqft
      )
        .multiply(this.lease_sqft)
        .round(2)
        .done();
    }
    if (e.target.name == "lease_quick_check[tenant_improvement_costs_total]") {
      console.log("hello");
      this.tenant_imporovement_costs_sqftTarget.value = chain(
        this.tenant_improvement_costs_totalTarget.value
      )
        .divide(this.lease_sqft)
        .round(2)
        .done();
    }

    this.total_cost_aquire_tenantTarget.value = chain(broker_fees_total)
      .add(this.tenant_improvement_costs_totalTarget.value)
      .add(this.rent_buyoutsTarget.value)
      .add(this.other_feesTarget.value)
      .round(2)
      .done();
    // tenant_improvement_costs_total
  }

  calculate_broker_fees(e) {
    console.log(e);
    if (e.target.name == "tenant_info[broker_commision]") {
      var broker_fees_total = chain(this.broker_commisionTarget.value)
        .divide(100)
        .done();
      this.total_broker_feeTarget.value = chain(
        this.broker_annual_gross_rentTarget.value
      )
        .multiply(broker_fees_total)
        .round(2)
        .done();
      // console.log(broker_fees_total);
    } else {
      this.broker_commisionTarget.value = chain(
        this.total_broker_feeTarget.value
      )
        .divide(this.broker_annual_gross_rentTarget.value)
        .multiply(100)
        .round(2)
        .done();
      // console.log(broker_fees_total);
    }
  }

  refinance() {
    var total = chain(this.refinanceTarget.value).divide(100).round(2).done();
    this.cash_out_refinanceTarget.value = chain(
      this.increased_property_valueTarget.value
    )
      .multiply(total)
      .round(2)
      .done();
    this.cash_out_refinance_costsTarget.value = chain(
      this.cash_out_refinanceTarget.value
    )
      .subtract(this.total_cost_aquire_tenantTarget.value)
      .round(2)
      .done();
  }

  summary_analyses() {
    // console.log(this.w2_increased_valueTarget.value);
    var w1_cap_rate = chain(this.w1_cap_rateTarget.value).divide(100).done();
    console.log(w1_cap_rate, this.total_rent_base_lease_pctTarget.value);
    this.w2_increased_valueTarget.value = chain(
      this.total_rent_base_lease_pctTarget.value
    )
      .divide(w1_cap_rate)
      .done();

    this.w4_cash_out_new_leaseTarget.value = chain(
      this.w2_increased_valueTarget.value
    )
      .multiply(this.w3_refinanceTarget.value)
      .divide(100)
      .round(2)
      .done();

    this.w5_cash_out_one_timeTarget.value = chain(
      this.w4_cash_out_new_leaseTarget.value
    )
      .subtract(this.total_time_costsTarget.value)
      .round(2)
      .done();
    if (
      (this.entry_yearTarget.value != null) &
      (this.w1_cap_rate_xTarget.value != null)
    ) {
      // send an ajax request to the server to get the data
      console.log("ajax her");
      // $.ajax({
      //   url: '/summary_analyses_year/' + this.summary_analyses_idTarget.value,
      //   type: 'GET',
      //   data: { year: this.entry_yearTarget.value },
      // }).then(this.w2data_increased(data, e));
      fetch(
        "/summary_analyses_year/" +
          this.summary_analyses_idTarget.value +
          "?year=" +
          this.entry_yearTarget.value
      )
        .then((response) => response.text())
        .then(
          (html) => {
            var data = JSON.parse(html);
            this.w2_increased_value_xTarget.value = chain(parseFloat(data))
              .divide(this.w1_cap_rate_xTarget.value)
              .multiply(100)
              .round(2)
              .done();
          }
          // (this.w2_increased_value_xTarget.value = chain(parseFloat(html))
          //   .divide(this.w1_cap_rate_xTarget.value)
          //   .round(2)
          //   .done())
        );
    }
    console.log(
      this.w2_increased_value_xTarget.value,
      this.w3_refinance_xTarget.value
    );
    this.w4_cash_out_new_lease_xTarget.value = chain(
      this.w2_increased_value_xTarget.value
    )
      .multiply(this.w3_refinance_xTarget.value)
      .divide(100)
      .round(2)
      .done();
    this.w5_cash_out_one_time_xTarget.value = chain(
      this.w4_cash_out_new_lease_xTarget.value
    )
      .subtract(this.total_time_costsTarget.value)
      .round(2)
      .done();

    this.year_labelTarget.textContent = this.entry_yearTarget.value;
  }
  w2_increased(e, data) {
    console.log(this.w2_increased_value_xTarget.value, e, data);
    this.w2_increased_value_xTarget.value = chain(data)
      .divide(this.w1_cap_rate_xTarget.value)
      .round(2)
      .done();
  }

  get lease_sqft() {
    return this.lease_sqftTarget.value;
  }

  get tenant_imporovement_costs_sqft() {
    return this.tenant_imporovement_costs_sqftTarget.value;
  }

  get rentable_sqft() {
    return this.rentable_sqftTarget.value;
  }

  get annual_base_rent_sqft() {
    return this.annual_base_rent_sqftTarget.value;
  }

  get total_annual_expenses() {
    return this.total_annual_expensesTarget.value;
  }

  get percentage_rentable_space() {
    return this.percentage_rentable_spaceTarget.value;
  }

  get annual_gross_rent() {
    return this.annual_gross_rentTarget.value;
  }

  get actual_cam_recapture() {
    return this.actual_cam_recaptureTarget.value;
  }

  get annual_gross_income() {
    return this.annual_gross_incomeTarget.value;
  }

  get term_years() {
    return this.term_yearsTarget.value;
  }

  get w2_increased_value_x() {
    return this.w2_increased_value_xTarget.value;
  }

  get lease_type() {
    return this.lease_typeTarget.value;
  }

  get broker_fees() {
    return this.broker_feesTarget.value;
  }
}
